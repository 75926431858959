<header class="top-bar" role="banner">
    <div class="container">
        <nav role="navigation">
            <div class="row d-flex align-items-center">
                <div class="col-lg-3">
                    <div class="logo-wrapper">
                        <a class="logo link" href="#/"><img src="assets/img/logo-default.svg"
                                alt="Client Hub Logo"></a>
                        <i class="visible-xs visible-sm visible-md feather menu menu-icon"></i>
                    </div>
                </div>
                <div class="col-lg-6">
                    <ul class="nav-list text-center">
                        <li>
                            <a class="link" href="#/">Home</a>
                        </li>

                        <li>
                            <a class="link link-scroll" href="#pricing">Pricing</a>
                        </li>

                        <li>
                            <a class="link" href="#/about-us">About Us</a>
                        </li>
                        <li dropdown placement="bottom right"
                                style="position: relative; font-size: 16px; color: #0075FF; cursor: pointer">
                                <div dropdownToggle>
                                    View Demo
                                    <i class="feather chevron-down"></i>
                                </div>
                                <ul *dropdownMenu class="dropdown-menu" role="menu">
                                    <li role="menuitem">
                                        <a (click)="openModal(demoModal)">Watch it now</a>
                                    </li>
                                    <li role="menuitem">
                                        <a (click)="openModal(demoScheduleModal)">Schedule meeting</a>
                                    </li>
                                </ul>
                            </li>

                        <li class="hidden visible-xs visible-sm visible-md">
                            <button class="btn btn-primary-light sign-in" routerLink="login">Sign In</button>
                        </li>
                        <li class="hidden visible-xs visible-sm visible-md">
                            <button class="btn btn-primary focus-hero" style="float: right;"
                                (click)="startRegistration()">Try It</button>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 text-right">
                    <button class="btn btn-primary-light hidden visible-lg" style="float: left;" routerLink="login">Sign
                        In</button>
                    <button class="btn btn-primary focus-hero hidden visible-lg" style="float: right;"
                        (click)="startRegistration()">Try It</button>
                </div>
            </div>
        </nav>
    </div>
</header>

<main id="start" class="fake-body">

    <section id="before-after">
        <div class="container text-center">
            <div class="section-title text-center">
                <h1>Go from “Bottlenecks” to “Frictionless”</h1>
            </div>

            <div class="row d-flex align-items-center">
                <div class="col-md-4">
                    <img src="/assets/img/before-after-page/7-1.png">
                </div>
                <div class="col-md-4 text-center">
                    <p>Radically increase throughput by putting  your team, your clients, your communication, and your files all in one place</p>
                </div>
                <div class="col-md-4">
                    <img src="/assets/img/before-after-page/7-2.png">
                </div>
            </div>
            
            <h2 class="text-center" style="font-size: 32px;">6 Specific Wins with Client Hub</h2>
            
            <h2 style="margin-top: 0;">1. From “Back-and-Forth” to “Instant Answers”</h2>
            <div class="row sticky">
                <div class="col-md-6 text-center">
                    <div class="label before">Before</div>
                </div>
                <div class="col-md-6 text-center">
                    <div class="label after">After</div>
                </div>
            </div>
            <div class="row d-flex align-items-end">
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/1-1.png">
                    <ul>
                        <li>Long threads of Q&A</li>
                        <li>Hard to track what’s outstanding</li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/1-2.png">
                    <ul>
                        <li>Quick mobile-ready Client Tasks</li>
                        <li>Automatically tracks outstanding tasks</li>
                    </ul>
                </div>
            </div>
            
            <h2>2. From “Siloed Communication” to “Consolidated View”</h2>
            <div class="row d-flex align-items-end">
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/2-1.png">
                    <ul>
                        <li>Threads in individual inboxes</li>
                        <li>Siloed texting in parallel</li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/2-2.png">
                    <ul>
                        <li>No more cc, bcc, forward etc</li>
                        <li>Consolidate all communication</li>
                    </ul>
                </div>
            </div>
            
            <h2>3. From “Detach, Attach, Download” to “Seamless Sharing”</h2>
            <div class="row d-flex align-items-end">
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/3-1.png">
                    <ul>
                        <li>File sharing in yet another app</li>
                        <li>Many clicks to download, save, view</li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/3-2.png">
                    <ul>
                        <li>Secure file sharing integrated</li>
                        <li>Seamlessly view in the cloud</li>
                    </ul>
                </div>
            </div>
            
            <h2>4. From “Groundhog Day” to “Empowered Clients”</h2>
            <div class="row d-flex align-items-end">
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/4-1.png">
                    <ul>
                        <li>Fulfill repeated requests for forms etc.</li>
                        <li>Answering ‘how to’ questions</li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/4-2.png">
                    <ul>
                        <li>Empower self-service</li>
                        <li>Add value with one-click resources</li>
                    </ul>
                </div>
            </div>
            
            <h2>5. From “Long Form” to “Modern Messaging”</h2>
            <div class="row d-flex align-items-end">
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/5-1.png">
                    <ul>
                        <li>Compose an email each time</li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/5-2.png">
                    <ul>
                        <li>Quick interactions, Modern experience</li>
                    </ul>
                </div>
            </div>
            
            <h2>6. From “OMG” to “Peace of Mind”</h2>
            <div class="row d-flex align-items-end">
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/6-1.png">
                    <ul>
                        <li>Clients putting SSNs etc. in email</li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <img src="/assets/img/before-after-page/6-2.png">
                    <ul>
                        <li>Bank-level security throughout</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <section id="pricing" class="text-center">
        <div class="container">
            <div class="section-title text-center">
                <h5>The Amazing Power of Streamlined Client Communication at a very affordable value</h5>
                <h2>14 Day Free Trial. No Credit Card Required. Cancel Anytime.</h2>
            </div>
            <div class="row d-flex align-items-center no-gutters">
               <div class="col-sm-2"></div>
               <div class="col-md-4">
                <div class="pricing-box pricing-left">
                    <div class="pricing-box-title">Standard</div>
                    <p class="pricing-box-description">All-in-One Client Collaboration Solution</p>
                    <hr>
                    <div class="pricing-box-price">
                        <p>$35 for first firm user per month</p>
                        <div class="middle-line">
                            <div class="plus">+</div>
                            <div class="big-price">$20</div>
                            <div class="aditional-text">per additional firm user per month</div>
                        </div>
                        <strong>Free for all Client Users </strong>
                    </div>
                    <hr>
                    <ul class="features-list text-left">
                        <li><div class="check-icon"></div><span>Unlimited clients</span></li>
                        <li><div class="check-icon"></div><span>Unlimited client users</span></li>
                        <li><div class="check-icon"></div><span>Track client tasks</span></li>
                        <li><div class="check-icon"></div><span>Client communication</span></li>
                        <li><div class="check-icon"></div><span>Client file sharing</span></li>
                        <li><div class="check-icon"></div><span>Manage Internal files</span></li>
                        <li><div class="check-icon"></div><span>Mobile app access</span></li>
                        <li><div class="check-icon"></div><span>And much, much more …</span></li>
                    </ul>
                </div>
               </div>
               <div class="col-md-4">
                <div class="pricing-box pricing-right">
                    <div class="pricing-box-title">Pro</div>
                    <p class="pricing-box-description">Add Automation to Save Even More Time</p>
                    <hr>
                    <div class="pricing-box-price">
                        <p>$45 for first firm user per month</p>
                        <div class="middle-line">
                            <div class="plus">+</div>
                            <div class="big-price">$30</div>
                            <div class="aditional-text">per additional firm user per month</div>
                        </div>
                        <strong>Free for all Client Users </strong>
                    </div>
                    <hr>
                    <ul class="features-list text-left">
                        <li><div class="check-icon"></div><span>Everything in Standard</span></li>
                        <li><div class="check-icon plus-icon"></div><span>Automated Client Tasks to Instantly resolve QuickBooks uncategorized expenses</span></li>
                    </ul>
                    <button (click)="startRegistration()" class="btn btn-success btn-block btn-lg">Get a Demo</button>
                    <strong>14-Day Free Trial with Pro features</strong>
                    <p class="decide">Decide on Standard / Pro at end of trial</p>
                </div>
               </div>
               <div class="col-sm-2"></div>
            </div>
        </div>
    </section>

  <footer>
      <div class="container footer-top">
          <div class="row">
              <div class="col-md-3">
                  <div class="footer-title">Comparisons</div>
                  <a href="/#/alternative/sharefile" target="_blank" rel="noreferrer noopener">Alternative to ShareFile</a>
                  <a href="/#/alternative/smartvault" target="_blank" rel="noreferrer noopener">Alternative to SmartVault</a>
                  <a href="/#/alternative/liscio" target="_blank" rel="noreferrer noopener">Alternative to Liscio</a>
              </div>
              <div class="col-md-3">
                  <div class="footer-title">Guides</div>
                  <a href="https://blog.clienthub.me/2-simple-models-to-share-and-manage-files-with-client-hub/" target="_blank" rel="noreferrer noopener">Sharing Files with Client Hub</a>
                  <a href="/#/quickbooks" target="_blank" rel="noreferrer noopener">QuickBooks Integration</a>
                  <a href="https://blog.clienthub.me/category/firm-spotlight/" target="_blank" rel="noreferrer noopener">Firm Spotlights</a>
                  <a (click)="openModal(availabilityModal)">Client Hub Service Uptime</a>
              </div>
              <div class="col-md-3">
                  <div class="footer-title">Solutions</div>
                  <a href="https://clienthub.me/solutions/secure-client-portal.html" target="_blank" rel="noreferrer noopener">Secure Client Portal</a>
                  <a href="https://clienthub.me/solutions/secure-file-sharing.html" target="_blank" rel="noreferrer noopener">Secure File Sharing</a>
              </div>
              <div class="col-md-3">
                  <div class="footer-title">About</div>
                  <a href="https://blog.clienthub.app/" target="_blank" rel="noreferrer noopener">Blog</a>
                  <a href="https://www.getrevue.co/profile/clienthub" target="_blank" rel="noreferrer noopener">Newsletter</a>
                  <a href="https://clienthub.app/#/about-us" target="_blank" rel="noreferrer noopener">About Us</a>
                  <address>
                      <div>
                          3615 SW13 Street, Suite 7<br>
                          Gainesville FL 32608, USA
                      </div>
                      <div>
                          Phone number: +1-352-559-6321<br>
                          Email Us: info at clienthub.app
                      </div>
                  </address>
              </div>
          </div>
          <hr>
      </div>
      <div class="footer-bottom">
          <div class="container">
              <div class="row">
                  <div class="col-sm-7 terms">
                      <a routerLink="/terms-of-service" style="margin-right: 15px;">Terms of Service</a>
                      <a routerLink="/privacy-policy" style="margin-right: 15px;">Privacy Policy</a>
                      <a routerLink="/data-security">Data Security</a>
                  </div>
                  <div class="col-sm-5 text-right social">
                      <a href="https://www.facebook.com/ClientHubApp/" target="_blank" rel="noreferrer noopener"><i
                              class="fa fa-facebook-official"></i></a>
                      <a href="https://twitter.com/@clienthubapp" target="_blank" rel="noreferrer noopener"><i class="fa fa-x-twitter"></i></a>
                      <a href="https://www.linkedin.com/company/11359354/" target="_blank" rel="noreferrer noopener"><i
                              class="fa fa-linkedin"></i></a>
                  </div>
              </div>
          </div>
      </div>
  </footer>
</main>

<ng-template #availabilityModal class="modal-lg">
    <div class="modal-header">
        <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
                class="feather x"></i></button>
        <h4 class="modal-title" id="myModalLabel">Availability</h4>
    </div>
    <div class="modal-body"
        style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
        <iframe style="width:100%;height:100%;" src="https://clienthub-609.freshstatus.io/" frameborder="0"></iframe>
    </div>
</ng-template>

<ng-template #demoModal class="modal-lg">
    <div class="modal-header">
        <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
                class="feather x"></i></button>
        <h4 class="modal-title" id="myModalLabel">Watch it now</h4>
    </div>
    <div class="modal-body"
        style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
        <iframe style="width:100%;height:100%;"
            src="https://pipedrivewebforms.com/form/c8e66402a250d860e9fb210c117b566b5203832" frameborder="0"></iframe>
    </div>
</ng-template>

<ng-template #demoScheduleModal class="modal-lg">
    <div class="modal-header">
        <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
                class="feather x"></i></button>
        <h4 class="modal-title" id="myModalLabel">Schedule meeting</h4>
    </div>
    <div class="modal-body"
        style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
        <iframe style="width:100%;height:100%;"
            src="https://calendly.com/judiech/discussion?month=2020-01" frameborder="0"></iframe>
    </div>
</ng-template>
<app-sign-up-drawer [signUpDrawerOpen]="signUpDrawerOpen" (signUpDrawerToggle)="signUpDrawerToggle($event)"></app-sign-up-drawer>