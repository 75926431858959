<div id="start" class="fake-body">
    <header id="top-bar" class="website-navigation">
        <div class="container animated fadeInDown">
            <div class="row">
                <div class="col-8 col-sm-3">
                    <a routerLink="/"><img class="logo-website" src="assets/img/logo-default.svg"
                            alt="Client Hub Logo"></a>
                </div>
                <div class="col-4 col-sm-9">
                    <i class="visible-xs feather menu menu-icon"></i>
                    <nav class="text-right " role="navigation">
                        <!-- <a routerLink="/" fragment="features">Features</a> -->
                        <!-- <a routerLink="/" fragment="faq">FAQ</a> -->
                        <a routerLink="/">Home</a>
                        <!-- <a class="attention" href="#earlyaccess" (click)="openModal(signUpModal)">Early Access</a> -->
                    </nav>
                </div>
            </div>
        </div>
    </header>

    <section class="terms-page animated fadeInUp">
        <div class="container">
            <h2>Client Hub Terms of Service</h2>
            <p>
                Effective Date: July 1, 2020
            </p>
            <h3>
                1. Introduction
            </h3>
            <p>
                Welcome to <strong>Client Hub Inc</strong> (“<strong>Company</strong>”, “<strong>we</strong>”, “<strong>our</strong>”, “<strong>us</strong>”)! As you have just
                clicked our Terms of Service, please pause, grab a cup of coffee and
                carefully read the following pages. It will take you approximately 20
                minutes.
            </p>
            <p>
                These Terms of Service (“<strong>Terms</strong>”, “<strong>Terms of Service</strong>”) govern your use of our
                web pages located at https://clienthub.app/#/ and our mobile application
                Client Hub (together or individually “<strong>Service</strong>”) operated by Client Hub Inc.
            </p>
            <p>
                Our Privacy Policy also governs your use of our Service and explains how we
                collect, safeguard and disclose information that results from your use of
                our web pages. Please read it here https://clienthub.app/#/privacy-policy.
            </p>
            <p>
                Your agreement with us includes these Terms and our Privacy Policy
                (“<strong>Agreements</strong>”). You acknowledge that you have read and understood
                Agreements, and agree to be bound by them.
            </p>
            <p>
                If you do not agree with (or cannot comply with) Agreements, then you may
                not use the Service, but please let us know by emailing: info&#64;clienthub.app
                so we can try to find a solution. These Terms apply to all visitors, users
                and others who wish to access or use Service.
            </p>
            <p>
                Thank you for being responsible.
            </p>
            <h3>
                2. Communications
            </h3>
            <p>
                By creating an Account on our Service, you agree to subscribe to
                newsletters, marketing or promotional materials and other information we
                may send. However, you may opt out of receiving any, or all, of these
                communications from us by following the unsubscribe link.
            </p>
            <h3>
                3. Subscriptions
            </h3>
            <p>
                The Client Hub Service is billed on a subscription basis
                (“<strong>Subscription(s)</strong>”). You will be billed in advance on a recurring and
                periodic basis (“<strong>Billing Cycle</strong>”). Billing cycles are set either on a
                monthly or annual basis, depending on the type of subscription plan you
                select when purchasing a Subscription.
            </p>
            <p>
                At the end of each Billing Cycle, your Subscription will automatically
                renew unless you cancel it or Client Hub Inc cancels it. You may cancel
                your Subscription renewal either through your online account management
                page or by contacting Client Hub Inc customer support team.
            </p>
            <p>
                A valid debit card or credit card, is required to process the payment for
                your subscription. You shall provide Client Hub Inc with accurate and
                complete billing information. By submitting such payment information, you
                automatically authorize Client Hub Inc to charge all Subscription fees
                incurred through your account to any such payment instruments.
            </p>
            <p>
                Should automatic billing fail to occur for any reason, Client Hub Inc will
                make several attempts to collect the past due amounts and will limit access
                to your account until the past due amounts are collected.
            </p>
            <h3>
                4. Free Trial
            </h3>
            <p>
                Client Hub Inc may, at its sole discretion, offer a Subscription with a
                free trial for a limited period of time (“<strong>Free Trial</strong>”).
            </p>
            <p>
                You may be required to enter your billing information in order to sign up
                for Free Trial.
            </p>
            <p>
                If you do enter your billing information when signing up for Free Trial,
                you will not be charged by Client Hub Inc until Free Trial has expired. On
                the last day of Free Trial period, unless you cancelled your Subscription,
                you will be automatically charged the applicable Subscription fees for the
                type of Subscription you have selected.
            </p>
            <p>
                At any time and without notice, Client Hub Inc reserves the right to (i)
                modify Terms of Service of Free Trial offer, or (ii) cancel such Free Trial
                offer.
            </p>
            <h3>
                5. Fee Changes
            </h3>
            <p>
                Client Hub Inc, in its sole discretion and at any time, may modify
                Subscription fees for the Subscriptions. Any Subscription fee change will
                become effective at the end of the then-current Billing Cycle.
            </p>
            <p>
                Client Hub Inc will provide you with a reasonable prior notice of any
                change in Subscription fees to give you an opportunity to terminate your
                Subscription before such change becomes effective.
            </p>
            <p>
                Your continued use of Service after Subscription fee change comes into
                effect constitutes your agreement to pay the modified Subscription fee
                amount.
            </p>
            <h3>
                6. Refunds
            </h3>
            <p>
                Except when required by law, paid Subscription fees are non-refundable.
            </p>
            <h3>
                7. Content
            </h3>
            <p>
                Our Service allows you to post, link, store, share and otherwise make
                available certain information, text, graphics, videos, or other material
                (“Content”). You are responsible for Content that you post on or through
                Service, including its legality, reliability, and appropriateness.
            </p>
            <h3>
                8. Prohibited Uses
            </h3>
            <p>
                You may use Service only for lawful purposes and in accordance with Terms.
                You agree not to use Service:
            </p>
            <ol type="a">
                <li>
                  In any way that violates any applicable national or international law
                  or regulation.
              </li>
              <li>
                  For the purpose of exploiting, harming, or attempting to exploit or
                  harm minors in any way by exposing them to inappropriate content or
                  otherwise.
              </li>
              <li>
                  To transmit, or procure the sending of, any advertising or promotional
                  material, including any “junk mail”, “chain letter,” “spam,” or any other
                  similar solicitation.
              </li>
              <li>
                  To impersonate or attempt to impersonate Company, a Company employee,
                  another user, or any other person or entity.
              </li>
              <li>
                  In any way that infringes upon the rights of others, or in any way is
                  illegal, threatening, fraudulent, or harmful, or in connection with any
                  unlawful, illegal, fraudulent, or harmful purpose or activity.
              </li>
              <li>
                  To engage in any other conduct that restricts or inhibits anyone’s use
                  or enjoyment of Service, or which, as determined by us, may harm or offend
                  Company or users of Service or expose them to liability.
              </li>
              </ol>
            <h4>
                Additionally, you agree not to:
            </h4>
            <ol type="a">
                <li>
                  Use Service in any manner that could disable, overburden, damage, or
                  impair Service or interfere with any other party’s use of Service,
                  including their ability to engage in real time activities through Service.
                </li>
                <li>
                  Use any robot, spider, or other automatic device, process, or means to
                  access Service for any purpose, including monitoring or copying any of the
                  material on Service.
                </li>
                <li>
                  Use any manual process to monitor or copy any of the material on
                  Service or for any other unauthorized purpose without our prior written
                  consent.
                </li>
                <li>
                  Use any device, software, or routine that interferes with the proper
                  working of Service.
                </li>
                <li>
                  Introduce any viruses, trojan horses, worms, logic bombs, or other
                  material which is malicious or technologically harmful.
                </li>
                <li>
                  Attempt to gain unauthorized access to, interfere with, damage, or
                  disrupt any parts of Service, the server on which Service is stored, or any
                  server, computer, or database connected to Service.
                </li>
                <li>
                  Attack Service via a denial-of-service attack or a distributed
                  denial-of-service attack.
                </li>
                <li>
                  Take any action that may damage or falsify Company rating.
                </li>
                <li>
                  Otherwise attempt to interfere with the proper working of Service.
                </li>
              </ol>
            <h3>
                9. Analytics
            </h3>
            <p>
                We may use third-party Service Providers to monitor and analyze the use of
                our Service. Additional information is in our Privacy Policy.
            </p>
            <h3>
                10. No Use By Minors
            </h3>
            <p>
                Service is intended only for access and use by individuals at least
                eighteen (18) years old. By accessing or using Service, you warrant and
                represent that you are at least eighteen (18) years of age and with the
                full authority, right, and capacity to enter into this agreement and abide
                by all of the terms and conditions of Terms. If you are not at least
                eighteen (18) years old, you are prohibited from both the access and usage
                of Service.
            </p>
            <h3>
                11. Accounts
            </h3>
            <p>
                When you create an account with us, you guarantee that you are above the
                age of 18, and that the information you provide us is accurate, complete,
                and current at all times. Inaccurate, incomplete, or obsolete information
                may result in the immediate termination of your account on Service.
            </p>
            <p>
                You are responsible for maintaining the confidentiality of your account and
                password, including but not limited to the restriction of access to your
                computer and/or account. You agree to accept responsibility for any and all
                activities or actions that occur under your account and/or password,
                whether your password is with our Service or a third-party service. You
                must notify us immediately upon becoming aware of any breach of security or
                unauthorized use of your account.
            </p>
            <p>
                You may not use as a username the name of another person or entity or that
                is not lawfully available for use, a name or trademark that is subject to
                any rights of another person or entity other than you, without appropriate
                authorization. You may not use as a username any name that is offensive,
                vulgar or obscene.
            </p>
            <p>
                We reserve the right to refuse service, terminate accounts, remove or edit
                content, or cancel orders in our sole discretion.
            </p>
            <h3>
                12. Intellectual Property
            </h3>
            <p>
                Service and its original content (excluding Content provided by users),
                features and functionality are and will remain the exclusive property of
                Client Hub Inc and its licensors. Service is protected by copyright,
                trademark, and other laws of the United States. Our trademarks and trade
                dress may not be used in connection with any product or service without the
                prior written consent of Client Hub Inc.
            </p>
            <h3>
                13. Copyright Policy
            </h3>
            <p>
                We respect the intellectual property rights of others. It is our policy to
                respond to any claim that Content posted on Service infringes on the
                copyright or other intellectual property rights (“<strong>Infringement</strong>”) of any
                person or entity.
            </p>
            <p>
                If you are a copyright owner, or authorized on behalf of one, and you
                believe that the copyrighted work has been copied in a way that constitutes
                copyright infringement, please submit your claim via email to info at
                clienthub.app, with the subject line: “Copyright Infringement” and include
                in your claim a detailed description of the alleged Infringement as
                detailed below, under “DMCA Notice and Procedure for Copyright Infringement
                Claims”
            </p>
            <p>
                You may be held accountable for damages (including costs and attorneys'
                fees) for misrepresentation or bad-faith claims on the infringement of any
                Content found on and/or through Service on your copyright.
            </p>
            <h3>
                14. DMCA Notice and Procedure for Copyright Infringement Claims
            </h3>
            <p>
                You may submit a notification pursuant to the Digital Millennium Copyright
                Act (DMCA) by providing our Copyright Agent with the following information
                in writing (see 17 U.S.C 512(c)(3) for further detail):
            </p>
            <ol type="a">
                <li>
                  an electronic or physical signature of the person authorized to act on
                  behalf of the owner of the copyright's interest;
                </li>
                <li>
                  a description of the copyrighted work that you claim has been
                  infringed, including the URL (i.e., web page address) of the location where
                  the copyrighted work exists or a copy of the copyrighted work;
                </li>
                <li>
                  identification of the URL or other specific location on Service where
                  the material that you claim is infringing is located;
                </li>
                <li>
                  your address, telephone number, and email address;
                </li>
                <li>
                  a statement by you that you have a good faith belief that the disputed
                  use is not authorized by the copyright owner, its agent, or the law;
                </li>
                <li>
                  a statement by you, made under penalty of perjury, that the above
                  information in your notice is accurate and that you are the copyright owner
                  or authorized to act on the copyright owner's behalf.
                </li>
              </ol>
            <p>
                You can contact our Copyright Agent via email at info at clienthub.app
            </p>
            <h3>
                15. Error Reporting and Feedback
            </h3>
            <p>
                You may provide us either directly at support at clienthub.app or via third
                party sites and tools with information and feedback concerning errors,
                suggestions for improvements, ideas, problems, complaints, and other
                matters related to our Service (“Feedback”). You acknowledge and agree
                that: (i) you shall not retain, acquire or assert any intellectual property
                right or other right, title or interest in or to the Feedback; (ii) Company
                may have development ideas similar to the Feedback; (iii) Feedback does not
                contain confidential information or proprietary information from you or any
                third party; and (iv) Company is not under any obligation of
                confidentiality with respect to the Feedback. In the event the transfer of
                the ownership to the Feedback is not possible due to applicable mandatory
                laws, you grant Company and its affiliates an exclusive, transferable,
                irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right
                to use (including copy, modify, create derivative works, publish,
                distribute and commercialize) Feedback in any manner and for any purpose.
            </p>
            <p>
                The third party sites and tools mentioned above include the following:
            </p>
            <p>
                Sentry
            </p>
            <p>
                Sentry is open-source error tracking solution provided by Functional
                Software Inc. More information is available here:
                https://sentry.io/privacy/
            </p>
            <p>
                Firebase Crashlytics
            </p>
            <p>
                Firebase Crashlytics is bug reporting service provided by Google Inc.
            </p>
            <p>
                You may opt-out of certain Firebase features through your mobile device
                settings, such as your device advertising settings or by following the
                instructions provided by Google in their Privacy Policy:
                https://policies.google.com/privacy?hl=en
            </p>
            <p>
                For more information on what type of information Firebase collects, please
                visit the Google Privacy Terms web page:
                https://policies.google.com/privacy?hl=en
            </p>
            <h3>
                16. Links To Other Web Sites
            </h3>
            <p>
                Our Service may contain links to third party web sites or services that are
                not owned or controlled by Client Hub Inc.
            </p>
            <p>
                Client Hub Inc has no control over, and assumes no responsibility for the
                content, privacy policies, or practices of any third party web sites or
                services. We do not warrant the offerings of any of these
                entities/individuals or their websites.
            </p>
            <p>
                YOU ACKNOWLEDGE AND AGREE THAT Client Hub Inc SHALL NOT BE RESPONSIBLE OR
                LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO
                BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT,
                GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR
                SERVICES.
            </p>
            <p>
                WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF
                ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
            </p>
            <h3>
                17. Disclaimer Of Warranty
            </h3>
            <p>
                THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE”
                BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS
                OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION,
                CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF
                THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US
                IS AT YOUR SOLE RISK.
            </p>
            <p>
                NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY
                OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
                QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE
                FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR
                WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS
                OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
                UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE
                SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            </p>
            <p>
                COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
                WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR
                PURPOSE.
            </p>
            <p>
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
                LIMITED UNDER APPLICABLE LAW.
            </p>
            <h3>
                18. Limitation Of Liability
            </h3>
            <p>
                EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS,
                EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL,
                INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING
                ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND
                ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR
                ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE,
                OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS
                AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR
                PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF
                ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF
                COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
                EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF
                COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
                SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR
                PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR
                EXCLUSION MAY NOT APPLY TO YOU.
            </p>
            <h3>
                19. Termination
            </h3>
            <p>
                We may terminate or suspend your account and bar access to Service
                immediately, without prior notice or liability, under our sole discretion,
                for any reason whatsoever and without limitation, including but not limited
                to a breach of Terms.
            </p>
            <p>
                If you wish to terminate your account, you may simply discontinue using
                Service and remove your payment information.
            </p>
            <p>
                All provisions of Terms which by their nature should survive termination
                shall survive termination, including, without limitation, ownership
                provisions, warranty disclaimers, indemnity and limitations of liability.
            </p>
            <h3>
                20. Governing Law
            </h3>
            <p>
                These Terms shall be governed and construed in accordance with the laws of
                State of Florida without regard to its conflict of law provisions.
            </p>
            <p>
                Our failure to enforce any right or provision of these Terms will not be
                considered a waiver of those rights. If any provision of these Terms is
                held to be invalid or unenforceable by a court, the remaining provisions of
                these Terms will remain in effect. These Terms constitute the entire
                agreement between us regarding our Service and supersede and replace any
                prior agreements we might have had between us regarding Service.
            </p>
            <h3>
                21. Changes To Service
            </h3>
            <p>
                We reserve the right to withdraw or amend our Service, and any service or
                material we provide via Service, in our sole discretion without notice. We
                will not be liable if for any reason all or any part of Service is
                unavailable at any time or for any period. From time to time, we may
                restrict access to some parts of Service, or the entire Service, to users,
                including registered users.
            </p>
            <h3>
                22. Amendments To Terms
            </h3>
            <p>
                We may amend Terms at any time by posting the amended terms on this site.
                It is your responsibility to review these Terms periodically.
            </p>
            <p>
                Your continued use of the Platform following the posting of revised Terms
                means that you accept and agree to the changes. You are expected to check
                this page frequently so you are aware of any changes, as they are binding
                on you.
            </p>
            <p>
                By continuing to access or use our Service after any revisions become
                effective, you agree to be bound by the revised terms. If you do not agree
                to the new terms, you are no longer authorized to use Service.
            </p>
            <h3>
                23. Waiver And Severability
            </h3>
            <p>
                No waiver by Company of any term or condition set forth in Terms shall be
                deemed a further or continuing waiver of such term or condition or a waiver
                of any other term or condition, and any failure of Company to assert a
                right or provision under Terms shall not constitute a waiver of such right
                or provision.
            </p>
            <p>
                If any provision of Terms is held by a court or other tribunal of competent
                jurisdiction to be invalid, illegal or unenforceable for any reason, such
                provision shall be eliminated or limited to the minimum extent such that
                the remaining provisions of Terms will continue in full force and effect.
            </p>
            <h3>
                24. Acknowledgement
            </h3>
            <p>
                BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU
                HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
            </p>
            <h3>
                25. Contact Us
            </h3>
            <p>
                Please send your feedback, comments, requests for technical support:
            </p>
            <p>
                By email: info at clienthub.app.
            </p>
            <div>
                <br/>
            </div>
        </div>
    </section>

    <footer>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-sm-7 terms">
                        <a routerLink="/terms-of-service" style="margin-right: 15px;">Terms of Service</a>
                        <a routerLink="/privacy-policy" style="margin-right: 15px;">Privacy Policy</a>
                        <a routerLink="/data-security">Data Security</a>
                    </div>
                    <div class="col-sm-5 text-right social">
                        <a href="https://www.facebook.com/ClientHubApp/" target="_blank" rel="noreferrer noopener"><i
                                class="fa fa-facebook-official"></i></a>
                        <a href="https://twitter.com/@clienthubapp" target="_blank" rel="noreferrer noopener"><i class="fa fa-x-twitter"></i></a>
                        <a href="https://www.linkedin.com/company/11359354/" target="_blank" rel="noreferrer noopener"><i
                                class="fa fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>

<ng-template #signUpModal class="modal-lg">
    <div class="modal-header">
        <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
                class="feather x"></i></button>
        <h4 class="modal-title" id="myModalLabel">Early access sign up</h4>
    </div>
    <div class="modal-body"
        style="padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
        <iframe style="width:100%;height:100%;" class="scrollable"
            src="https://docs.google.com/forms/d/e/1FAIpQLScFZef8aBpkwHvGEs_tgJyOD1fu5Eh1FklGH9C9d4gpVc2Bgw/viewform"
            frameborder="0"></iframe>
    </div>
</ng-template>