<app-navigation></app-navigation>
<main id="start" class="fake-body">
  
  <section id="hero" class="why-choose-us-hero">
    <div class="container">
      <h1 class="text-center">
        Why Our Customers Select Us
      </h1>
      <p class="text-center" style="font-weight: normal;
      font-size: 28px;
      text-align: center;
      color: #485364;
      display: block;
      margin-top: 8px;
      margin-bottom: 42px;
      line-height: 42px;">Because we’re right for them: an <strong>all-in-one platform</strong> that is <strong>sophisticated yet amazingly simple</strong> — coming from an accountant-led company that’s truly <strong>customer-first</strong>.</p>
      <img class="img-responsive" src="assets/img/landing-page-v4/group-of-people.png" alt="Image of a group of people">
    </div>
  </section>

  <section id="why-choose-us-cards">
    <div class="container">
      <div class="row d-flex">
        <div class="col-md-4">
          <div class="card">
            <div class="card-content">
              <h3>What we do…</h3>
              <p>We cover managing workflow, managing files, and managing client communication all in one place.</p>
              <p>And we build client experience into the platform — and that eliminates so much friction in your workflow.</p>
              <p>And we have several unique capabilities including our <a href="https://clienthub.app/#/quickbooks">award-winning feature</a> that automates resolving QuickBooks unclassified transactions.</p>
            </div>
            <a href="/#/all-in-one" class="card-footer">
              See our All-In-One Practice Platform page
            </a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-content">
              <h3>How we do it…</h3>
              <p>We are obsessed with user experience and fully believe that this is our sustainable advantage.</p>
              <p>Our key design principles are: </p>
              <p>Client-centric</p>
              <p>Instantly Obvious </p>
              <p>Modern Metaphors</p>
              <p>All together, it adds up to “Sophisticated Yet Simple”.</p>
            </div>
            <a href="/#/sophisticated-yet-simple" class="card-footer">
              See our Sophisticated Yet Simple page
            </a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-content">
              <h3>Who we are… from the profession</h3>
              <p>We’re on your side: we are an accountant-founded, self-funded business that really understands your workflow and our roadmap will always be driven by meeting your goals (not those of external investors).</p>
            </div>
            <a href="/#/about-us" class="card-footer">
              See our About Us page 
            </a>
          </div>
        </div>
      </div>

      <div class="big-cta blue">
        <div class="row">
            <div class="col-sm-3 col-md-3 cta-illustration">
                <img style="right: 0;" src="assets/img/landing-page-v4/billy-cta-1.png" alt="Image of an accountant">
            </div>
            <div class="col-sm-5 col-md-6">
                <div class="title">Ready to Put All-in-One to Work in Your Practice?</div>
            </div>
            <div class="col-sm-3 col-md-3 text-right">
                <button class="btn btn-primary" (click)="startRegistration()">Get a Demo</button>
            </div>
        </div>
      </div>
    </div>
  </section>

</main>
<app-footer></app-footer>
<app-sign-up-drawer [signUpDrawerOpen]="signUpDrawerOpen" (signUpDrawerToggle)="signUpDrawerToggle($event)"></app-sign-up-drawer>