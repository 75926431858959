import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class FooterComponent implements OnInit {
  public modalRef: BsModalRef;

  constructor(
    public modalService: BsModalService,
  ) { }

  ngOnInit(): void {
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

}
