<div class="loading" *ngIf="loading" style="border-radius: 16px">
  <div class="loader">Loading...</div>
</div>

<div *ngIf="!loading" style="padding-top: 50px">
  <form [formGroup]="emailForm">
    <div class="row" *ngIf="invite == null || invite === undefined">
      <div class="col-sm-6 col-sm-offset-3">
        <h3>Join Client Hub</h3>
        <p>
          Simply enter your individual work email address to get started. This
          will be used as your username and for notifications on client and
          staff interactions.
        </p>
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label for="email">Email address</label>
              <input id="email" type="email" [ngClass]="
                  formUtil.displayFieldCss(
                    emailForm,
                    'email',
                    emailFormSubmitAttempt
                  )
                " formControlName="email" class="form-control" placeholder="name@domain.com" />
            </div>
          </div>
        </div>
        <button class="btn btn-lg btn-primary" [disabled]="!emailForm.valid" (click)="check_email()">
          That's my email address <i class="feather chevron-right"></i>
        </button>
      </div>
    </div>
  </form>

  <div class="row" *ngIf="invite !== null && invite !== undefined && !loading">
    <div class="col-sm-6 offset-sm-3 col-md-4 offset-md-4 text-center">
      <div>
        <img src="assets/img/logo-default.svg" width="150" alt="Client Hub" class="mb-5" />
      </div>
      <img *ngIf="invite == false" src="assets/img/practice-team-illustration.svg" alt="Team" width="280" />
      <img *ngIf="invite == true && invite_info.invited_to !== null" src="assets/img/team-illustration.svg" alt="Team"
        width="280" />
      <img *ngIf="invite == true && invite_info.invited_to == null" src="assets/img/team-illustration.svg" alt="Team"
        width="280" />
      <div class="text-left">
        <br />

        <!-- invite == true and there's a company tied to the invite. Can be an accountant or a client -->
        <div *ngIf="invite == true && invite_info.invited_to !== null">
          <a class="choose-link" [routerLink]="['/registration/create']">
            <i class="feather x3 users pull-left" style="margin-right: 15px"></i>
            <strong>Join {{ invite_info.invited_to.name }}</strong>
            <p>
              You were invited by {{ invite_info.invited_by.first_name }}
              {{ invite_info.invited_by.last_name }}.
            </p>
          </a>
          <hr />
        </div>
        <!-- invite == true and invited to a company == null, its a new client invited by an accountant -->
        <div *ngIf="invite == true && invite_info.invited_to == null">
          <a class="choose-link" [routerLink]="['/registration/create']">
            <i class="feather x3 plus pull-left" style="margin-right: 15px"></i>
            <strong>Set up a new company</strong>
            <p>Welcome to Client Hub.</p>
          </a>
        </div>
        <!-- Invite == False, user is Accountant  -->
        <div *ngIf="invite == false">
          <a class="choose-link" [routerLink]="['/registration/create']">
            <i class="feather x3 plus pull-left" style="margin-right: 15px"></i>
            <strong>Set up a new practice</strong>
            <p>Welcome to Client Hub.</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Invite link modal -->
<div class="modal fade" id="modal-invite" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="myModalLabel">Share your invite link with teammates</h4>
      </div>
      <div class="modal-body">
        <p>
          Anyone can use the following link to join your Practice. Just copy and
          share it!
        </p>
        <div class="row">
          <div class="col-md-8">
            <input type="text" readonly class="form-control"
              value="https://clienthub.app/registration/join/3Muj6lPb4i9b9fkG7KvrHJKL6FkO4G7HgU7KnG8H"
              onfocus="this.select();" />
          </div>
          <div class="col-md-4">
            <button class="btn btn-lg btn-primary copy-url">
              <i class="feather clipboard"></i> Copy to clipboard
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>