<!-- <div class="fake-body white">
	<header>
		<div class="container">
			<div class="row">
				<div class="col-sm-6 col-sm-offset-3">
						<img src="assets/img/logo-default.svg" width="150" alt="Cloud Client Hub">
				</div>
			</div>
		</div>
	</header>

	<div class="container">

	  <router-outlet></router-outlet>

	</div>
</div> -->

<div class="fake-body white">
	<router-outlet></router-outlet>
</div>