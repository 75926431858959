import { environment } from "src/environments/environment";
import { LoginService } from "src/app/login/login.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class TaggingService {
  constructor(public _http: HttpClient, public loginService: LoginService) {}

  tagAnonymousUser(
    email: string,
    firstName: string,
    lastName: string,
    firmSize: string,
    leadSource: string,
    ownerPhone: string
  ) {
    return this._http.post(
      `${environment.apiUrl}/integration/common/tag/anonymous`,
      { email, firstName, lastName, firmSize, leadSource, ownerPhone }
    );
  }

  tagAnonymousUserPromise(
    email: string,
    firstName: string,
    lastName: string,
    firmSize: string,
    leadSource: string,
    ownerPhone: string
  ) {
    return this._http
      .post(`${environment.apiUrl}/integration/common/tag/anonymous`, {
        email,
        firstName,
        lastName,
        firmSize,
        leadSource,
        ownerPhone,
      })
      .toPromise();
  }
}
