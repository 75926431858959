<header class="top-bar animated fadeInDown" role="banner">
    <div class="container">
        <nav role="navigation">
            <a class="logo link" routerLink="/"><img src="assets/img/logo-default.svg" alt="Client Hub Logo"></a>
            <ul class="nav-list">
                <li>
                    <a class="link" routerLink="/">Home</a>
                </li>
            </ul>
        </nav>
    </div>
</header>

<div class="container">
    <div class="mobile-app-section" style="transform: none; min-height:100vh;">
        <div class="row d-flex align-items-center workspace-example" style="min-height:100vh;">
            <div class="col-sm-8">
                <div class="section-title" style="margin-bottom:20px;">
                    <h2 style="margin-bottom:0">Mobile Apps for iOS and Android</h2>
                </div>
                <h3 style="color: #000;font-weight: 400; line-height: 38px;cursor:default;margin: 0;">Exchange messages and files with clients from anywhere.</h3>
                <div class="row center-xs">
                    <div class="col-6 col-sm-6 col-md-4">
                        <a href="https://itunes.apple.com/us/app/client-hub/id1426064081?mt=8" target="_blank" rel="noreferrer noopener">
                            <img style="margin-top:30px" class="img-responsive" src="assets/img/landing-page-v3/app-store.png" alt="Download on the App Store">
                        </a>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4">
                        <a href="https://play.google.com/store/apps/details?id=com.clienthub.clienthub&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" rel="noreferrer noopener">
                            <img style="margin-top:30px" class="img-responsive" src="assets/img/landing-page-v3/google-play.png" alt="Get it on Google Play">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <img class="img-responsive" src="assets/img/landing-page-v3/app.png"
                    alt="Mobile App">
            </div>
        </div>
    </div>
</div>

<div style="
background: #f4f5f7;
position:fixed;
top:0; right:0; bottom:0; left:0;
z-index:-1;
pointer-events:none
"></div>