<app-navigation></app-navigation>
<main id="start" class="fake-body">
  
  <section id="hero" class="product-tour-hero">
    <div class="container">
        <h1 style="margin-bottom: 42px">Product Tour</h1>
        <!-- <h1>Quick Overview… </h1> -->
        <!-- <p>Check out our 2-minute video and screenshots of our key features below </p> -->
        <div class="row">
            <div class="col-md-8">
              <h3>Only Have a Minute?</h3>
              <p>Click below to explore our seamless experience for your team and your clients.</p>
              <div id="sophisticated-yet-simple-cards" style="margin-bottom: 42px">
                <div class="cards">
                  <div class="row d-flex align-items-center">
                    <div class="col-md-6">
                      <a style="display:block" href="#explore" class="card team-card" (click)="sectionToDisplay = 'team'">
                        <img src="assets/img/landing-page-v4/team-experience.png" alt="A happy team member">
                        <h3>Explore Your Team’s Experience</h3>
                      </a>
                    </div>
                    <div class="col-md-6">
                      <a style="display:block" href="#explore" class="card client-card" (click)="sectionToDisplay = 'client'">
                        <img src="assets/img/landing-page-v4/instantly-obvious.png" alt="A happy customer">
                        <h3>Explore Your Clients’ Experience</h3>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="">
                <h4>Ready to dive deeper?</h4>
                <p style="margin-bottom: 5px">Check out a complete demo.<br><br></p>
                <button class="btn btn-primary" (click)="startRegistration()">Get a Demo</button>
              </div>
            </div>
        </div>
    </div>
    <div id="explore"></div>
  </section>

  <section id="every-thing-your-team-needs" [hidden]="sectionToDisplay == ''" style="padding-top:0">
    <div class="container">

      <!-- Team -->
      <div class="team-section" [hidden]="sectionToDisplay != 'team'">
        <div class="section-title text-center">
          <h2>
            Everything Your Team Needs
            <small>Organized by Client</small>
          </h2>
        </div>

        <div class="feature-category">
          <div class="row d-flex">
            <div class="col-md-8">
              <div class="features-sticky">
                <div class="feature-title">
                  <strong>Internal Workspace:</strong> for your team to organize client service delivery
                </div>
                <div class="feature-preview feature-preview-1">
                  <img class="img-responsive" src="assets/img/landing-page-v4/product-tour/1_staff_jobs-panel.jpg">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <ul class="features-list">
                <li class="active" data-img="1_staff_jobs-panel.jpg">
                  <div class="title">Jobs Panel</div>
                  <div class="description">Manage deliverables and deadlines</div>
                </li>
                <li data-img="1_staff_task-checklist.jpg">
                  <div class="title">Task Checklist</div>
                  <div class="description">Create simple-to-follow checklists</div>
                </li>
                <li data-img="1_staff_task-details.jpg">
                  <div class="title">Task Details</div>
                  <div class="description">Give your team details that can include images/video</div>
                </li>
                <li data-img="1_staff_recurring-jobs.jpg">
                  <div class="title">Recurring Jobs</div>
                  <div class="description">Perfect for ongoing client service</div>
                </li>
                <li data-img="1_staff_client-notes.jpg">
                  <div class="title">Client Notes</div>
                  <div class="description">Internal notes for your team</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      
        <div class="feature-category">
          <div class="row d-flex">
            <div class="col-md-8">
              <div class="features-sticky">
                <div class="feature-title">
                  <strong>Client Workspace:</strong> where you and your clients collaborate
                </div>
                <div class="feature-preview feature-preview-2">
                  <img class="img-responsive" src="assets/img/landing-page-v4/product-tour/2_staff_conversations.jpg">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <ul class="features-list green">
                <li class="active" data-img="2_staff_conversations.jpg">
                  <div class="title">Conversations</div>
                  <div class="description">Consolidate client communication. Everyone on your team always in the loop</div>
                </li>
                <li data-img="2_staff_client-tasks.jpg">
                  <div class="title">Client Tasks</div>
                  <div class="description">Gather outstanding items from clients and get workflow unblocked</div>
                </li>
                <li data-img="2_staff_apps.jpg">
                  <div class="title">Apps</div>
                  <div class="description">Give clients their accounting app stack in one place</div>
                </li>
                <li data-img="2_staff_resources.jpg">
                  <div class="title">Resources</div>
                  <div class="description">Give clients access to procedures, training, and more in one click</div>
                </li>
                <li data-img="2_staff_qb-integration.jpg">
                  <div class="title">QuickBooks Integration</div>
                  <div class="description">Instantly create Client Tasks to resolve uncategorized expenses</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="feature-category">
          <div class="row d-flex">
            <div class="col-md-8">
              <div class="features-sticky">
                <div class="feature-title">
                  <strong>File Manager:</strong> a fully integrated way to organize and share files
                </div>
                <div class="feature-preview feature-preview-3">
                  <img class="img-responsive" src="assets/img/landing-page-v4/product-tour/3_staff_recent-files.jpg">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <ul class="features-list orange">
                <li class="active" data-img="3_staff_recent-files.jpg">
                  <div class="title">Recent Files</div>
                  <div class="description">Convenient one-click access to recently added files</div>
                </li>
                <li data-img="3_staff_client-shared-folder.jpg">
                  <div class="title">Client Shared Folders</div>
                  <div class="description">Organize folders and store files that are shared with clients</div>
                </li>
                <li data-img="3_staff_staff-only-folder.jpg">
                  <div class="title">Internal Folders</div>
                  <div class="description">Internal folders and files, organized under each client</div>
                </li>
                <li data-img="3_staff_auto-downloader.jpg">
                  <div class="title">Auto-Downloader <span style="font-weight: normal;">(Beta)</span></div>
                  <div class="description">Automatically downloads files for local Windows or Mac access</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="feature-category">
          <div class="row d-flex">
            <div class="col-md-8">
              <div class="features-sticky">
                <div class="feature-title">
                  <strong>Dashboards:</strong> at-a-glance visibility across your firm
                </div>
                <div class="feature-preview feature-preview-4">
                  <img class="img-responsive" src="assets/img/landing-page-v4/product-tour/4_staff_jobs-dashboard.jpg">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <ul class="features-list purple">
                <li class="active" data-img="4_staff_jobs-dashboard.jpg">
                  <div class="title">Jobs Dashboard</div>
                  <div class="description">Drive all the work in your practice (and easily manage who is doing what for which client)</div>
                </li>
                <li data-img="4_staff_client-tasks-dashboard.jpg">
                  <div class="title">Client Task  Dashboard</div>
                  <div class="description">Visualize all the things that you are waiting on from your clients (try doing that in your email program!)</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>

      <!-- Clients -->
      <div class="client-section" [hidden]="sectionToDisplay != 'client'">
        <div class="section-title text-center" style="padding-top:0">
          <h2>
            Drop-dead Simple for Your Clients
            <small>Here is what your clients see — and love</small>
          </h2>
        </div>

        <div class="feature-category">
          <div class="row d-flex">
            <div class="col-md-8">
              <div class="features-sticky">
                <div class="feature-title">
                  <strong>Client Workspace:</strong> where clients collaborate with your team
                </div>
                <div class="feature-preview feature-preview-5">
                  <img class="img-responsive" src="assets/img/landing-page-v4/product-tour/5_client_conversations.jpg">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <ul class="features-list teal">
                <li class="active" data-img="5_client_conversations.jpg">
                  <div class="title">Conversations</div>
                  <div class="description">Clients love being able to communicate in a quick text-like way</div>
                </li>
                <li data-img="5_client_client-tasks.jpg">
                  <div class="title">Client Tasks</div>
                  <div class="description">The simplest way for clients to answer their client tasks</div>
                </li>
                <li data-img="5_client_apps.jpg">
                  <div class="title">Apps</div>
                  <div class="description">Clients love one-click access to their apps, bank accounts etc</div>
                </li>
                <li data-img="5_client_resources.jpg">
                  <div class="title">Resources</div>
                  <div class="description">Clients click to schedule meetings, attend meetings, access forms and more</div>
                </li>
                <li data-img="5_client_qb-integration.jpg">
                  <div class="title">QuickBooks Integration</div>
                  <div class="description">Clients on QuickBooks Online instantly resolve unclassified expenses</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="feature-category">
          <div class="row d-flex">
            <div class="col-md-8">
              <div class="features-sticky">
                <div class="feature-title">
                  <strong>File Manager:</strong> where clients access their files
                </div>
                <div class="feature-preview feature-preview-6">
                  <img class="img-responsive" src="assets/img/landing-page-v4/product-tour/6_client_recent-files.jpg">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <ul class="features-list">
                <li class="active" data-img="6_client_recent-files.jpg">
                  <div class="title">Recent Files</div>
                  <div class="description">Clients access recently added files in one-click</div>
                </li>
                <li data-img="6_client_client-shared-folders.jpg">
                  <div class="title">Client Shared Folders</div>
                  <div class="description">Clients access their folders and files shared by your team</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="feature-category">
          <div class="row d-flex">
            <div class="col-md-8">
              <div class="features-sticky">
                <div class="feature-title">
                  <strong>Mobile App:</strong> modernize your client experience
                </div>
                <div class="feature-preview feature-preview-7">
                  <img class="img-responsive" src="assets/img/landing-page-v4/product-tour/7_client_mobile-conversations.jpg">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <ul class="features-list green">
                <li class="active" data-img="7_client_mobile-conversations.jpg">
                  <div class="title">Conversations</div>
                  <div class="description">Clients reach your firm while they are on the go</div>
                </li>
                <li data-img="7_client_mobile-client-tasks.jpg">
                  <div class="title">Client Tasks</div>
                  <div class="description">And they can answer tasks while on the go</div>
                </li>
                <!-- <li data-img="7_client_mobile-recent-files.jpg">
                  <div class="title">Files</div>
                  <div class="description">And even access their files!</div>
                </li> -->
              </ul>
            </div>
          </div>
        </div>

        <div class="feature-category">
          <div class="row d-flex">
            <div class="col-md-8">
              <div class="features-sticky">
                <div class="feature-title">
                  <strong>Notifications and More:</strong> minimize change in client behavior
                </div>
                <div class="feature-preview feature-preview-8">
                  <img class="img-responsive" src="assets/img/landing-page-v4/product-tour/8_client_mobile-email-notifications.jpg">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <ul class="features-list orange">
                <li class="active" data-img="8_client_mobile-email-notifications.jpg">
                  <div class="title">Email notifications</div>
                  <div class="description">Client Hub experience starts here — where they already are</div>
                </li>
                <li data-img="8_client_mobile-notifications.jpg">
                  <div class="title">Mobile notifications</div>
                  <div class="description">…or it can start here for clients who are out-and-about</div>
                </li>
                <li data-img="8_client_mobile-deep-links.jpg">
                  <div class="title">Deep links</div>
                  <div class="description">And from notifications, they go immediately into their Client Workspace (without logging in again)</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="sophisticated-yet-simple-cards" [hidden]="sectionToDisplay == ''" style="margin-bottom: 42px">
        <div class="cards">
          <div class="row d-flex align-items-center">
            <div class="col-md-12" [hidden]="sectionToDisplay == 'team'">
              <a href="#explore" style="display:block" class="card team-card" (click)="sectionToDisplay = 'team'">
                <img src="assets/img/landing-page-v4/team-experience.png" alt="A happy team member">
                <h3>Explore Your Team’s Experience</h3>
              </a>
            </div>
            <div class="col-md-12" [hidden]="sectionToDisplay == 'client'">
              <a href="#explore" style="display:block" class="card client-card" (click)="sectionToDisplay = 'client'">
                <img src="assets/img/landing-page-v4/instantly-obvious.png" alt="A happy customer">
                <h3>Explore Your Clients’ Experience</h3>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <section id="simple-as-1-2-3" [hidden]="sectionToDisplay == ''">
      <div class="container">
          <div class="section-title text-center">
              <h2>Getting Going is As Simple as 1, 2 and 3…</h2>
          </div>
          <div class="row">
            <div class="col-md-4">
              <img class="img-responsive" style="max-height: 280px;" src="assets/img/landing-page-v4/simple-1.png" alt="Image of happy users">
              <p>
                <strong>1.</strong>
                Sign up for Client Hub and invite your accounting or bookkeeping practice staff.
              </p>
            </div>
            <div class="col-md-4">
              <img class="img-responsive" style="max-height: 280px;" src="assets/img/landing-page-v4/simple-2.png" alt="Image representing the clients list and staff">
              <p>
                <strong>2.</strong>
                Add each client company. Invite client users and assign staff to serve this client.
              </p>
            </div>
            <div class="col-md-4">
              <img class="img-responsive" style="max-height: 280px;" src="assets/img/landing-page-v4/simple-3.png" alt="Image representing the clients workspace and features">
              <p>
                <strong>3.</strong>
                For each client, you get a hub with: (a) An Internal Workspace, (b) A Client Workspace and (c) A File Manager.
              </p>
            </div>
          </div>
      </div>
    </section>

    <div class="container" [hidden]="sectionToDisplay == ''">
      <div class="big-cta purple">
        <div class="row">
            <div class="col-sm-3 col-md-3 cta-illustration">
                <img src="assets/img/landing-page-v4/billy-cta-2.png" alt="Image of an accountant">
            </div>
            <div class="col-sm-5 col-md-6">
                <div class="title">Ready to Put All-in-One to Work in Your Practice?</div>
            </div>
            <div class="col-sm-3 col-md-3 text-right">
                <button class="btn btn-purple" (click)="startRegistration()">Get a Demo</button>
            </div>
        </div>
      </div>
    </div>

  </section>

</main>
<app-footer></app-footer>

<ng-template #demoModal class="modal-lg">
  <div class="modal-header">
      <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
              class="feather x"></i></button>
      <h4 class="modal-title" id="myModalLabel">Watch it now</h4>
  </div>
  <div class="modal-body"
      style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
      <iframe style="width:100%;height:100%;"
          src="https://pipedrivewebforms.com/form/c8e66402a250d860e9fb210c117b566b5203832" frameborder="0"></iframe>
  </div>
</ng-template>

<ng-template #demoScheduleModal class="modal-lg">
  <div class="modal-header">
      <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
              class="feather x"></i></button>
      <h4 class="modal-title" id="myModalLabel">Schedule meeting</h4>
  </div>
  <div class="modal-body"
      style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
      <iframe style="width:100%;height:100%;"
          src="https://calendly.com/judiech/discussion?month=2020-01" frameborder="0"></iframe>
  </div>
</ng-template>
<app-sign-up-drawer [signUpDrawerOpen]="signUpDrawerOpen" (signUpDrawerToggle)="signUpDrawerToggle($event)"></app-sign-up-drawer>