<app-navigation></app-navigation>
<main id="start" class="fake-body">
  
  <section id="hero" class="customer-council-hero">
    <div class="container">
        <h1>Customer Advisory Council</h1>
        <p>Our Customer Advisory Council works with us to together shape great product and explore strategies that drive growth across our community.</p>

        <div class="council-members text-center">
          <div class="row">
            <div class="col-md-4">
              <div class="council-member" (click)="seeMember(1)">
                <img src="assets/img/council/1.jpg" style="max-width: 250px" alt="Jacob Curtis">
                <div class="name">Jacob Curtis</div>
                <div class="company">Curtis Accounting Solution</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="council-member" (click)="seeMember(8)">
                <img src="assets/img/council/8.jpg" style="max-width: 250px" alt="Wendelyn Duquette">
                <div class="name">Wendelyn Duquette</div>
                <div class="company">QuickStart LLC</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="council-member" (click)="seeMember(5)">
                <img src="assets/img/council/5.jpg" style="max-width: 250px" alt="Cortnie Grno">
                <div class="name">Cortnie Grno</div>
                <div class="company">Mai Solutions Business Services</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="council-member" (click)="seeMember(3)">
                <img src="assets/img/council/3.jpg" style="max-width: 250px" alt="Renae Leahy">
                <div class="name">Renae Leahy</div>
                <div class="company">Bay Bookkeeping Services</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="council-member" (click)="seeMember(2)">
                <img src="assets/img/council/2.jpg" style="max-width: 250px" alt="Stephanie Mack">
                <div class="name">Stephanie Mack</div>
                <div class="company">Account Mobility</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="council-member" (click)="seeMember(4)">
                <img src="assets/img/council/4.jpg" style="max-width: 250px" alt="Ibi Ojo">
                <div class="name">Ibi Ojo</div>
                <div class="company">Fortune Accounting & Business Solutions, Inc.</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="council-member" (click)="seeMember(7)">
                <img src="assets/img/council/7.jpg" style="max-width: 250px" alt="Meredith Smith">
                <div class="name">Meredith Smith</div>
                <div class="company">Streamlined Accounting Strategies</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="council-member" (click)="seeMember(9)">
                <img src="assets/img/council/9.jpg" style="max-width: 250px" alt="Dillon Towey">
                <div class="name">Dillon Towey</div>
                <div class="company">Franchise Resource LLC</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="council-member" (click)="seeMember(6)">
                <img src="assets/img/council/6.jpg" style="max-width: 250px" alt="Cheryl Willett">
                <div class="name">Cheryl Willett</div>
                <div class="company">Willett Business Management, Inc.</div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </section>

  <div class="council-members-drawer" [ngClass]="{ 'drawer-open': drawerOpen }">
    <i class="feather x close-drawer" (click)="closeDrawer()"></i>

    <!-- 1 -->
    <div class="member" *ngIf="member == 1">
      <img class="profile-picture" src="assets/img/council/1.jpg" alt="Jacob Curtis">
      <div class="name">Jacob Curtis</div>
      <div class="company">Curtis Accounting Solution</div>
      <!-- Links -->
      <div class="links-wrapper">
        <a class="links" href="https://www.curtisaccountingsolutions.com/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7745 9.06919C18.1008 8.69851 18.0692 8.13038 17.7041 7.80055L9.65968 0.529829C9.29455 0.200003 8.70914 0.206912 8.35169 0.545405L0.279921 8.1882C-0.0775289 8.52669 -0.0950728 9.0941 0.241078 9.45502L0.443448 9.67272C0.779188 10.0336 1.32187 10.0767 1.6548 9.76854L2.25804 9.21053V17.3799C2.25804 17.8812 2.65122 18.2869 3.13611 18.2869H6.28251C6.7674 18.2869 7.16059 17.8812 7.16059 17.3799V11.6646H11.1739V17.3799C11.1669 17.8808 11.5138 18.2866 11.9987 18.2866H15.3331C15.818 18.2866 16.2112 17.8808 16.2112 17.3796V9.32556C16.2112 9.32556 16.3778 9.47635 16.5834 9.66296C16.7885 9.84921 17.2195 9.69987 17.5458 9.32883L17.7745 9.06919Z" fill="#0075FF"/>
            </svg>
             Website
        </a>
        <a class="links" href="https://www.linkedin.com/in/jacob-curtis-profit-strategist/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0ZM6.36328 14.2734H4.25391V6.89062H6.36328V14.2734ZM6.36328 5.83594H4.25391V3.72656H6.36328V5.83594ZM13.7461 14.2734H11.6367V10.0547C11.6367 9.47324 11.1635 9 10.582 9C10.0006 9 9.52734 9.47324 9.52734 10.0547V14.2734H7.41797V6.89062H9.52734V7.28819C10.08 7.11639 10.4389 6.89062 11.1094 6.89062C12.5399 6.89214 13.7461 8.17548 13.7461 9.69214V14.2734Z" fill="#0075FF"/>
            </svg>
             LinkedIn
        </a>
        <a class="links" href="https://www.facebook.com/jacobscurtiscpa" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H7.94531V11.6367H5.83594V8.47266H7.94531V6.32812C7.94531 4.58336 9.36461 3.16406 11.1094 3.16406H14.3086V6.32812H11.1094V8.47266H14.3086L13.7812 11.6367H11.1094V18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0Z" fill="#0075FF"/>
            </svg>
             Facebook
        </a>
      </div>
      <!-- // Links -->

      <!-- Quote -->
      <div class="quote">
        At Curtis Accounting Solutions, we know that most business owners struggle to manage their cash, making them feel stressed, anxious, and even incompetent, leading most to fail.
      <br><br>We believe that most companies deserve to thrive, so we provide a simple cash management system that can help business owners see their finances at a glance, helping them manage their cash effectively and actually grow their businesses.
      </div>
      <!-- // Quote -->
    </div>
    <!-- // 1 -->

    <!-- 2 -->
    <div class="member" *ngIf="member == 2">
      <img class="profile-picture" src="assets/img/council/2.jpg" alt="Stephanie Mack">
      <div class="name">Stephanie Mack</div>
      <div class="company">Account Mobility</div>
      <!-- Links -->
      <div class="links-wrapper">
        <a class="links" href="https://accountmobility.com/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7745 9.06919C18.1008 8.69851 18.0692 8.13038 17.7041 7.80055L9.65968 0.529829C9.29455 0.200003 8.70914 0.206912 8.35169 0.545405L0.279921 8.1882C-0.0775289 8.52669 -0.0950728 9.0941 0.241078 9.45502L0.443448 9.67272C0.779188 10.0336 1.32187 10.0767 1.6548 9.76854L2.25804 9.21053V17.3799C2.25804 17.8812 2.65122 18.2869 3.13611 18.2869H6.28251C6.7674 18.2869 7.16059 17.8812 7.16059 17.3799V11.6646H11.1739V17.3799C11.1669 17.8808 11.5138 18.2866 11.9987 18.2866H15.3331C15.818 18.2866 16.2112 17.8808 16.2112 17.3796V9.32556C16.2112 9.32556 16.3778 9.47635 16.5834 9.66296C16.7885 9.84921 17.2195 9.69987 17.5458 9.32883L17.7745 9.06919Z" fill="#0075FF"/>
            </svg>
             Website
        </a>
        <a class="links" href="https://www.linkedin.com/in/accountmobility/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0ZM6.36328 14.2734H4.25391V6.89062H6.36328V14.2734ZM6.36328 5.83594H4.25391V3.72656H6.36328V5.83594ZM13.7461 14.2734H11.6367V10.0547C11.6367 9.47324 11.1635 9 10.582 9C10.0006 9 9.52734 9.47324 9.52734 10.0547V14.2734H7.41797V6.89062H9.52734V7.28819C10.08 7.11639 10.4389 6.89062 11.1094 6.89062C12.5399 6.89214 13.7461 8.17548 13.7461 9.69214V14.2734Z" fill="#0075FF"/>
            </svg>
             LinkedIn
        </a>
        <a class="links" href="https://www.facebook.com/AccountMobility" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H7.94531V11.6367H5.83594V8.47266H7.94531V6.32812C7.94531 4.58336 9.36461 3.16406 11.1094 3.16406H14.3086V6.32812H11.1094V8.47266H14.3086L13.7812 11.6367H11.1094V18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0Z" fill="#0075FF"/>
            </svg>
             Facebook
        </a>
        <a class="links" href="https://twitter.com/StephanieMack25" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.1538 2.13092C21.33 2.49231 20.4522 2.73185 19.5369 2.84815C20.4785 2.286 21.1971 1.40262 21.5349 0.337846C20.6571 0.861231 19.6878 1.23092 18.6549 1.43723C17.8214 0.549693 16.6334 0 15.3374 0C12.8229 0 10.7986 2.04092 10.7986 4.54292C10.7986 4.90292 10.8291 5.24908 10.9038 5.57862C7.128 5.39446 3.78692 3.58477 1.54246 0.828C1.15062 1.50785 0.920769 2.286 0.920769 3.12369C0.920769 4.69662 1.73077 6.09092 2.93815 6.89815C2.20846 6.88431 1.49262 6.67246 0.886154 6.33877C0.886154 6.35262 0.886154 6.37062 0.886154 6.38862C0.886154 8.59569 2.46046 10.4289 4.52492 10.8512C4.15523 10.9523 3.75231 11.0008 3.33415 11.0008C3.04338 11.0008 2.74985 10.9842 2.47431 10.9232C3.06277 12.7218 4.73262 14.0442 6.71815 14.0871C5.17292 15.2958 3.21092 16.0242 1.08692 16.0242C0.714462 16.0242 0.357231 16.0075 0 15.9618C2.01185 17.2592 4.39615 18 6.96738 18C15.3249 18 19.8942 11.0769 19.8942 5.076C19.8942 4.87523 19.8872 4.68138 19.8775 4.48892C20.7789 3.84923 21.5363 3.05031 22.1538 2.13092Z" fill="#0075FF"/>
            </svg>
             Twitter
        </a>
      </div>
      <!-- // Links -->

      <!-- Quote -->
      <div class="quote">
        I helped two family members run their small businesses for 15 years. It was HARD! From trying to figure out how to manage payroll to offering pre-payment discounts to generate sales to implementing Field Service Apps. I have experienced the difficulties operating a small business, so I know the do's & don'ts of good operational workflow & the importance of good finances ready in a click. So I am passionate on making sure my clients have me as a reference & my team produces closed books for the clients in a collaborative & timely manner!
      </div>
      <!-- // Quote -->
    </div>
    <!-- // 2 -->

    <!-- 3 -->
    <div class="member" *ngIf="member == 3">
      <img class="profile-picture" src="assets/img/council/3.jpg" alt="Renae Leahy">
      <div class="name">Renae Leahy</div>
      <div class="company">Bay Bookkeeping Services</div>
      <!-- Links -->
      <div class="links-wrapper">
        <a class="links" href="https://baybkservices.com/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7745 9.06919C18.1008 8.69851 18.0692 8.13038 17.7041 7.80055L9.65968 0.529829C9.29455 0.200003 8.70914 0.206912 8.35169 0.545405L0.279921 8.1882C-0.0775289 8.52669 -0.0950728 9.0941 0.241078 9.45502L0.443448 9.67272C0.779188 10.0336 1.32187 10.0767 1.6548 9.76854L2.25804 9.21053V17.3799C2.25804 17.8812 2.65122 18.2869 3.13611 18.2869H6.28251C6.7674 18.2869 7.16059 17.8812 7.16059 17.3799V11.6646H11.1739V17.3799C11.1669 17.8808 11.5138 18.2866 11.9987 18.2866H15.3331C15.818 18.2866 16.2112 17.8808 16.2112 17.3796V9.32556C16.2112 9.32556 16.3778 9.47635 16.5834 9.66296C16.7885 9.84921 17.2195 9.69987 17.5458 9.32883L17.7745 9.06919Z" fill="#0075FF"/>
            </svg>
             Website
        </a>
        <a class="links" href="https://www.linkedin.com/in/renae-leahy-81880532/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0ZM6.36328 14.2734H4.25391V6.89062H6.36328V14.2734ZM6.36328 5.83594H4.25391V3.72656H6.36328V5.83594ZM13.7461 14.2734H11.6367V10.0547C11.6367 9.47324 11.1635 9 10.582 9C10.0006 9 9.52734 9.47324 9.52734 10.0547V14.2734H7.41797V6.89062H9.52734V7.28819C10.08 7.11639 10.4389 6.89062 11.1094 6.89062C12.5399 6.89214 13.7461 8.17548 13.7461 9.69214V14.2734Z" fill="#0075FF"/>
            </svg>
             LinkedIn
        </a>
        <a class="links" href="https://www.facebook.com/BayBkServices" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H7.94531V11.6367H5.83594V8.47266H7.94531V6.32812C7.94531 4.58336 9.36461 3.16406 11.1094 3.16406H14.3086V6.32812H11.1094V8.47266H14.3086L13.7812 11.6367H11.1094V18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0Z" fill="#0075FF"/>
            </svg>
             Facebook
        </a>
      </div>
      <!-- // Links -->

      <!-- Quote -->
      <div class="quote">
        I love working with numbers and have a passion for helping individuals, businesses and organizations get their finances in order. I started Bay Bookkeeping Services so I could do do both, while also giving me the flexibility to be there for my family. Family comes first, and frequently, business owners get bogged down trying to do too much. I want to help you get back some time for the important things in life, while also providing a service that helps you see in detail where your money is coming from and where it is going. Smart business owners need this information to make important decisions for the growth of your business.
      </div>
      <!-- // Quote -->
    </div>
    <!-- // 3 -->

    <!-- 4 -->
    <div class="member" *ngIf="member == 4">
      <img class="profile-picture" src="assets/img/council/4.jpg" alt="Ibi Ojo">
      <div class="name">Ibi Ojo</div>
      <div class="company">Fortune Accounting & Business Solutions, Inc.</div>
      <!-- Links -->
      <div class="links-wrapper">
        <a class="links" href="https://fortuneaccounting.com/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7745 9.06919C18.1008 8.69851 18.0692 8.13038 17.7041 7.80055L9.65968 0.529829C9.29455 0.200003 8.70914 0.206912 8.35169 0.545405L0.279921 8.1882C-0.0775289 8.52669 -0.0950728 9.0941 0.241078 9.45502L0.443448 9.67272C0.779188 10.0336 1.32187 10.0767 1.6548 9.76854L2.25804 9.21053V17.3799C2.25804 17.8812 2.65122 18.2869 3.13611 18.2869H6.28251C6.7674 18.2869 7.16059 17.8812 7.16059 17.3799V11.6646H11.1739V17.3799C11.1669 17.8808 11.5138 18.2866 11.9987 18.2866H15.3331C15.818 18.2866 16.2112 17.8808 16.2112 17.3796V9.32556C16.2112 9.32556 16.3778 9.47635 16.5834 9.66296C16.7885 9.84921 17.2195 9.69987 17.5458 9.32883L17.7745 9.06919Z" fill="#0075FF"/>
            </svg>
             Website
        </a>
        <a class="links" href="https://www.linkedin.com/in/ibi-ojo-04361317/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0ZM6.36328 14.2734H4.25391V6.89062H6.36328V14.2734ZM6.36328 5.83594H4.25391V3.72656H6.36328V5.83594ZM13.7461 14.2734H11.6367V10.0547C11.6367 9.47324 11.1635 9 10.582 9C10.0006 9 9.52734 9.47324 9.52734 10.0547V14.2734H7.41797V6.89062H9.52734V7.28819C10.08 7.11639 10.4389 6.89062 11.1094 6.89062C12.5399 6.89214 13.7461 8.17548 13.7461 9.69214V14.2734Z" fill="#0075FF"/>
            </svg>
             LinkedIn
        </a>
        <a class="links" href="https://www.facebook.com/Fortune-Accounting-and-Business-Solutions-Inc-100301431957681" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H7.94531V11.6367H5.83594V8.47266H7.94531V6.32812C7.94531 4.58336 9.36461 3.16406 11.1094 3.16406H14.3086V6.32812H11.1094V8.47266H14.3086L13.7812 11.6367H11.1094V18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0Z" fill="#0075FF"/>
            </svg>
             Facebook
        </a>
      </div>
      <!-- // Links -->

      <!-- Quote -->
      <div class="quote" style="font-size: 20px;">
        I started my business first, because everywhere I turned in my little town I was told I was overqualified and there was no open position that that fits my credentials. I stayed on inspite of all the challenges of being a sole manager because I found that small businesses lacked the professional help to succeed. They often did not know or understand the importance of bookkeeping, tax planning, entity type and tax responsibility of owning a business. They often believe that they are too small or cannot afford to hire an accountant. As a result, the business is poorly managed, they have no accurate and reliable bookkeeping system, often fall back on paying payroll taxes, filing tax forms and eventually lose their business. My ultimate pleasure comes from helping them get set up, put processes in place to help them run their business succesfully and most importantly see their face beam as they live out their dreams: becoming an entrepreneur in whatever industry they operate in.
      </div>
      <!-- // Quote -->
    </div>
    <!-- // 4 -->

    <!-- 5 -->
    <div class="member" *ngIf="member == 5">
      <img class="profile-picture" src="assets/img/council/5.jpg" alt="Cortnie Grno">
      <div class="name">Cortnie Grno</div>
      <div class="company">Mai Solutions Business Services</div>
      <!-- Links -->
      <div class="links-wrapper">
        <a class="links" href="https://maisolutionsnow.com/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7745 9.06919C18.1008 8.69851 18.0692 8.13038 17.7041 7.80055L9.65968 0.529829C9.29455 0.200003 8.70914 0.206912 8.35169 0.545405L0.279921 8.1882C-0.0775289 8.52669 -0.0950728 9.0941 0.241078 9.45502L0.443448 9.67272C0.779188 10.0336 1.32187 10.0767 1.6548 9.76854L2.25804 9.21053V17.3799C2.25804 17.8812 2.65122 18.2869 3.13611 18.2869H6.28251C6.7674 18.2869 7.16059 17.8812 7.16059 17.3799V11.6646H11.1739V17.3799C11.1669 17.8808 11.5138 18.2866 11.9987 18.2866H15.3331C15.818 18.2866 16.2112 17.8808 16.2112 17.3796V9.32556C16.2112 9.32556 16.3778 9.47635 16.5834 9.66296C16.7885 9.84921 17.2195 9.69987 17.5458 9.32883L17.7745 9.06919Z" fill="#0075FF"/>
            </svg>
             Website
        </a>
        <a class="links" href="https://www.linkedin.com/in/cortnie-grno-68992b52/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0ZM6.36328 14.2734H4.25391V6.89062H6.36328V14.2734ZM6.36328 5.83594H4.25391V3.72656H6.36328V5.83594ZM13.7461 14.2734H11.6367V10.0547C11.6367 9.47324 11.1635 9 10.582 9C10.0006 9 9.52734 9.47324 9.52734 10.0547V14.2734H7.41797V6.89062H9.52734V7.28819C10.08 7.11639 10.4389 6.89062 11.1094 6.89062C12.5399 6.89214 13.7461 8.17548 13.7461 9.69214V14.2734Z" fill="#0075FF"/>
            </svg>
             LinkedIn
        </a>
        <a class="links" href="https://www.facebook.com/maisolutionsnow" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H7.94531V11.6367H5.83594V8.47266H7.94531V6.32812C7.94531 4.58336 9.36461 3.16406 11.1094 3.16406H14.3086V6.32812H11.1094V8.47266H14.3086L13.7812 11.6367H11.1094V18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0Z" fill="#0075FF"/>
            </svg>
             Facebook
        </a>
      </div>
      <!-- // Links -->

      <!-- Quote -->
      <div class="quote">
        I started my practice because I realized there was an immense need for reliable, professional, and honest bookkeepers. Bookkeepers that had a passion for their clients' success as much as their own! I love what I do because I get to train, inspire, and support clients in their day to day accounting needs. I am passionate about having a work life balance and motivating my clients to do the same. As a mom of 2 I understand what running a business is like and how simplifying our overflowing task lists are just as important to our daily success.
      </div>
      <!-- // Quote -->
    </div>
    <!-- // 5 -->

    <!-- 6 -->
    <div class="member" *ngIf="member == 6">
      <img class="profile-picture" src="assets/img/council/6.jpg" alt="Cheryl Willett">
      <div class="name">Cheryl Willett</div>
      <div class="company">Willett Business Management, Inc.</div>
      <!-- Links -->
      <div class="links-wrapper">
        <a class="links" href="https://willettbusinessmgmt.com/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7745 9.06919C18.1008 8.69851 18.0692 8.13038 17.7041 7.80055L9.65968 0.529829C9.29455 0.200003 8.70914 0.206912 8.35169 0.545405L0.279921 8.1882C-0.0775289 8.52669 -0.0950728 9.0941 0.241078 9.45502L0.443448 9.67272C0.779188 10.0336 1.32187 10.0767 1.6548 9.76854L2.25804 9.21053V17.3799C2.25804 17.8812 2.65122 18.2869 3.13611 18.2869H6.28251C6.7674 18.2869 7.16059 17.8812 7.16059 17.3799V11.6646H11.1739V17.3799C11.1669 17.8808 11.5138 18.2866 11.9987 18.2866H15.3331C15.818 18.2866 16.2112 17.8808 16.2112 17.3796V9.32556C16.2112 9.32556 16.3778 9.47635 16.5834 9.66296C16.7885 9.84921 17.2195 9.69987 17.5458 9.32883L17.7745 9.06919Z" fill="#0075FF"/>
            </svg>
             Website
        </a>
        <a class="links" href="https://www.linkedin.com/in/cheryl-willett-willettbusiness1972/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0ZM6.36328 14.2734H4.25391V6.89062H6.36328V14.2734ZM6.36328 5.83594H4.25391V3.72656H6.36328V5.83594ZM13.7461 14.2734H11.6367V10.0547C11.6367 9.47324 11.1635 9 10.582 9C10.0006 9 9.52734 9.47324 9.52734 10.0547V14.2734H7.41797V6.89062H9.52734V7.28819C10.08 7.11639 10.4389 6.89062 11.1094 6.89062C12.5399 6.89214 13.7461 8.17548 13.7461 9.69214V14.2734Z" fill="#0075FF"/>
            </svg>
             LinkedIn
        </a>
        <a class="links" href="https://www.facebook.com/cheryl.willett.946/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H7.94531V11.6367H5.83594V8.47266H7.94531V6.32812C7.94531 4.58336 9.36461 3.16406 11.1094 3.16406H14.3086V6.32812H11.1094V8.47266H14.3086L13.7812 11.6367H11.1094V18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0Z" fill="#0075FF"/>
            </svg>
             Facebook
        </a>
      </div>
      <!-- // Links -->

      <!-- Quote -->
      <div class="quote">
        Understanding the need for support and education to SMBs' to manage their finances helped me define my purpose to make an impact in the community with my love for accounting. As my practice grew from bookkeeping to training, support and business advisory services it became clear I could make a greater impact by working with business owners to develop and implement processes helping to improve efficiency. Working with the owners provides an opportunity to help them better understand their financials as they work towards their goals and the story their financials tell about their business.
      </div>
      <!-- // Quote -->
    </div>
    <!-- // 6 -->

    <!-- 7 -->
    <div class="member" *ngIf="member == 7">
      <img class="profile-picture" src="assets/img/council/7.jpg" alt="Meredith Smith">
      <div class="name">Meredith Smith</div>
      <div class="company">Streamlined Accounting Strategies</div>
      <!-- Links -->
      <div class="links-wrapper">
        <a class="links" href="https://streamlinedstrat.com/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7745 9.06919C18.1008 8.69851 18.0692 8.13038 17.7041 7.80055L9.65968 0.529829C9.29455 0.200003 8.70914 0.206912 8.35169 0.545405L0.279921 8.1882C-0.0775289 8.52669 -0.0950728 9.0941 0.241078 9.45502L0.443448 9.67272C0.779188 10.0336 1.32187 10.0767 1.6548 9.76854L2.25804 9.21053V17.3799C2.25804 17.8812 2.65122 18.2869 3.13611 18.2869H6.28251C6.7674 18.2869 7.16059 17.8812 7.16059 17.3799V11.6646H11.1739V17.3799C11.1669 17.8808 11.5138 18.2866 11.9987 18.2866H15.3331C15.818 18.2866 16.2112 17.8808 16.2112 17.3796V9.32556C16.2112 9.32556 16.3778 9.47635 16.5834 9.66296C16.7885 9.84921 17.2195 9.69987 17.5458 9.32883L17.7745 9.06919Z" fill="#0075FF"/>
            </svg>
             Website
        </a>
        <a class="links" href="https://www.linkedin.com/in/meredithsmithcpa/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0ZM6.36328 14.2734H4.25391V6.89062H6.36328V14.2734ZM6.36328 5.83594H4.25391V3.72656H6.36328V5.83594ZM13.7461 14.2734H11.6367V10.0547C11.6367 9.47324 11.1635 9 10.582 9C10.0006 9 9.52734 9.47324 9.52734 10.0547V14.2734H7.41797V6.89062H9.52734V7.28819C10.08 7.11639 10.4389 6.89062 11.1094 6.89062C12.5399 6.89214 13.7461 8.17548 13.7461 9.69214V14.2734Z" fill="#0075FF"/>
            </svg>
             LinkedIn
        </a>
        <a class="links" href="https://www.facebook.com/meredith.smith.5623" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H7.94531V11.6367H5.83594V8.47266H7.94531V6.32812C7.94531 4.58336 9.36461 3.16406 11.1094 3.16406H14.3086V6.32812H11.1094V8.47266H14.3086L13.7812 11.6367H11.1094V18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0Z" fill="#0075FF"/>
            </svg>
             Facebook
        </a>
        <a class="links" href="https://twitter.com/streamlinedstrt" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.1538 2.13092C21.33 2.49231 20.4522 2.73185 19.5369 2.84815C20.4785 2.286 21.1971 1.40262 21.5349 0.337846C20.6571 0.861231 19.6878 1.23092 18.6549 1.43723C17.8214 0.549693 16.6334 0 15.3374 0C12.8229 0 10.7986 2.04092 10.7986 4.54292C10.7986 4.90292 10.8291 5.24908 10.9038 5.57862C7.128 5.39446 3.78692 3.58477 1.54246 0.828C1.15062 1.50785 0.920769 2.286 0.920769 3.12369C0.920769 4.69662 1.73077 6.09092 2.93815 6.89815C2.20846 6.88431 1.49262 6.67246 0.886154 6.33877C0.886154 6.35262 0.886154 6.37062 0.886154 6.38862C0.886154 8.59569 2.46046 10.4289 4.52492 10.8512C4.15523 10.9523 3.75231 11.0008 3.33415 11.0008C3.04338 11.0008 2.74985 10.9842 2.47431 10.9232C3.06277 12.7218 4.73262 14.0442 6.71815 14.0871C5.17292 15.2958 3.21092 16.0242 1.08692 16.0242C0.714462 16.0242 0.357231 16.0075 0 15.9618C2.01185 17.2592 4.39615 18 6.96738 18C15.3249 18 19.8942 11.0769 19.8942 5.076C19.8942 4.87523 19.8872 4.68138 19.8775 4.48892C20.7789 3.84923 21.5363 3.05031 22.1538 2.13092Z" fill="#0075FF"/>
            </svg>
             Twitter
        </a>
      </div>
      <!-- // Links -->

      <!-- Quote -->
      <div class="quote" style="font-size: 20px;">
        Meredith started her professional life in the non-profit world as a program manager. She quickly realized that her calling was in the numbers and took on the responsibilities of bookkeeper, accounting manager, then finance director at the many organizations she worked for, volunteered with and then supported as clients in her first bookkeeping practice.
        <br>To pursue her passion for accounting, Meredith returned to school and received her an accounting degree and took the CPA exam. Having worked in the field since before digitized accounting and bookkeeping were commonplace, Meredith is acutely aware of the value cloud-based accounting practices can provide to small businesses and nonprofits. She is excited to bring the technological advances in her field to individuals and organizations that haven’t had access to powerful accounting tools in the past. Meredith enjoys finding creative solutions to her clients’ accounting woes so they can work more efficiently and stay focused on the core of their work and calling.
      </div>
      <!-- // Quote -->
    </div>
    <!-- // 7 -->

    <!-- 8 -->
    <div class="member" *ngIf="member == 8">
      <img class="profile-picture" src="assets/img/council/8.jpg" alt="Wendelyn Duquette">
      <div class="name">Wendelyn Duquette</div>
      <div class="company">QuickStart LLC</div>
      <!-- Links -->
      <div class="links-wrapper">
        <a class="links" href="http://quickstartqb.com/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7745 9.06919C18.1008 8.69851 18.0692 8.13038 17.7041 7.80055L9.65968 0.529829C9.29455 0.200003 8.70914 0.206912 8.35169 0.545405L0.279921 8.1882C-0.0775289 8.52669 -0.0950728 9.0941 0.241078 9.45502L0.443448 9.67272C0.779188 10.0336 1.32187 10.0767 1.6548 9.76854L2.25804 9.21053V17.3799C2.25804 17.8812 2.65122 18.2869 3.13611 18.2869H6.28251C6.7674 18.2869 7.16059 17.8812 7.16059 17.3799V11.6646H11.1739V17.3799C11.1669 17.8808 11.5138 18.2866 11.9987 18.2866H15.3331C15.818 18.2866 16.2112 17.8808 16.2112 17.3796V9.32556C16.2112 9.32556 16.3778 9.47635 16.5834 9.66296C16.7885 9.84921 17.2195 9.69987 17.5458 9.32883L17.7745 9.06919Z" fill="#0075FF"/>
            </svg>
             Website
        </a>
        <a class="links" href="https://www.linkedin.com/in/wendelynduquettequickstart/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0ZM6.36328 14.2734H4.25391V6.89062H6.36328V14.2734ZM6.36328 5.83594H4.25391V3.72656H6.36328V5.83594ZM13.7461 14.2734H11.6367V10.0547C11.6367 9.47324 11.1635 9 10.582 9C10.0006 9 9.52734 9.47324 9.52734 10.0547V14.2734H7.41797V6.89062H9.52734V7.28819C10.08 7.11639 10.4389 6.89062 11.1094 6.89062C12.5399 6.89214 13.7461 8.17548 13.7461 9.69214V14.2734Z" fill="#0075FF"/>
            </svg>
             LinkedIn
        </a>
      </div>
      <!-- // Links -->

      <!-- Quote -->
      <div class="quote">
        QuickStart, LLC was founded from a desire to help organizations better utilize the power of QuickBooks through training, custom set-ups and consulting. We love seeing non-profit organization do what they do best, fulfilling their mission. By providing exceptional accounting services our clients can focus all their attention on their programs and clients and they are empowered to make the decisions with accurate and timey financial information at their finger tips. Our best days are those when our client succeed.
      </div>
      <!-- // Quote -->
    </div>
    <!-- // 8 -->

    <!-- 9 -->
    <div class="member" *ngIf="member == 9">
      <img class="profile-picture" src="assets/img/council/9.jpg" alt="Dillon Towey">
      <div class="name">Dillon Towey</div>
      <div class="company">Franchise Resource LLC</div>
      <!-- Links -->
      <div class="links-wrapper">
        <a class="links" href="http://www.franchiseresourcellc.com/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7745 9.06919C18.1008 8.69851 18.0692 8.13038 17.7041 7.80055L9.65968 0.529829C9.29455 0.200003 8.70914 0.206912 8.35169 0.545405L0.279921 8.1882C-0.0775289 8.52669 -0.0950728 9.0941 0.241078 9.45502L0.443448 9.67272C0.779188 10.0336 1.32187 10.0767 1.6548 9.76854L2.25804 9.21053V17.3799C2.25804 17.8812 2.65122 18.2869 3.13611 18.2869H6.28251C6.7674 18.2869 7.16059 17.8812 7.16059 17.3799V11.6646H11.1739V17.3799C11.1669 17.8808 11.5138 18.2866 11.9987 18.2866H15.3331C15.818 18.2866 16.2112 17.8808 16.2112 17.3796V9.32556C16.2112 9.32556 16.3778 9.47635 16.5834 9.66296C16.7885 9.84921 17.2195 9.69987 17.5458 9.32883L17.7745 9.06919Z" fill="#0075FF"/>
            </svg>
             Website
        </a>
        <a class="links" href="https://www.linkedin.com/in/dillon-towey-4b8483116/" target="_blank" rel="noreferrer noopener" rel="noopener noreferrer">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3633 0H2.63672C1.18295 0 0 1.18295 0 2.63672V15.3633C0 16.817 1.18295 18 2.63672 18H15.3633C16.817 18 18 16.817 18 15.3633V2.63672C18 1.18295 16.817 0 15.3633 0ZM6.36328 14.2734H4.25391V6.89062H6.36328V14.2734ZM6.36328 5.83594H4.25391V3.72656H6.36328V5.83594ZM13.7461 14.2734H11.6367V10.0547C11.6367 9.47324 11.1635 9 10.582 9C10.0006 9 9.52734 9.47324 9.52734 10.0547V14.2734H7.41797V6.89062H9.52734V7.28819C10.08 7.11639 10.4389 6.89062 11.1094 6.89062C12.5399 6.89214 13.7461 8.17548 13.7461 9.69214V14.2734Z" fill="#0075FF"/>
            </svg>
             LinkedIn
        </a>
      </div>
      <!-- // Links -->

      <!-- Quote -->
      <div class="quote">
        We started Franchise Resource because we saw a huge demand for franchise specific accounting. We are franchisees in a number of different brands and have also been on the franchisor side in the past. While operating in both those sectors, we came across a huge gap in service. Now, as a third party vendor in the franchise space, we fill that gap!
      </div>
      <!-- // Quote -->
    </div>
    <!-- // 9 -->

  </div>
  <div class="council-members-drawer-backdrop" (click)="closeDrawer()"></div>

</main>
<app-footer></app-footer>

<ng-template #demoModal class="modal-lg">
  <div class="modal-header">
      <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
              class="feather x"></i></button>
      <h4 class="modal-title" id="myModalLabel">Watch it now</h4>
  </div>
  <div class="modal-body"
      style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
      <iframe style="width:100%;height:100%;"
          src="https://pipedrivewebforms.com/form/c8e66402a250d860e9fb210c117b566b5203832" frameborder="0"></iframe>
  </div>
</ng-template>

<ng-template #demoScheduleModal class="modal-lg">
  <div class="modal-header">
      <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
              class="feather x"></i></button>
      <h4 class="modal-title" id="myModalLabel">Schedule meeting</h4>
  </div>
  <div class="modal-body"
      style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
      <iframe style="width:100%;height:100%;"
          src="https://calendly.com/judiech/discussion?month=2020-01" frameborder="0"></iframe>
  </div>
</ng-template>
<app-sign-up-drawer [signUpDrawerOpen]="signUpDrawerOpen" (signUpDrawerToggle)="signUpDrawerToggle($event)"></app-sign-up-drawer>