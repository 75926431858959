<app-navigation></app-navigation>
<main id="start" class="fake-body">

    <section id="hero" class="hero">
        <div class="container">
            <div class="row flex-row flex-center">
                <div class="col-md-6">
                    <img class="video-preview" (click)="openModal(videoModal)" src="assets/img/quickbooks-landing-page/video.png" alt="Video preview">
                </div>
                <div class="col-md-6">
                  <div class="hero-text-wrapper">
                    <a href="https://www.cpapracticeadvisor.com/accounting-audit/article/21147925/2020-innovation-award-winners-announced" class="btn btn-primary btn-primary-light" target="_blank" rel="noreferrer noopener">
                        2020 Innovation Awards - Finalist
                    </a>
                    <h1 class="animated fadeInUp delay-2" style="margin-top: 20px;">The End of Uncategorized Transactions</h1>
                    <p class="animated fadeInUp delay-3">Connect QuickBooks to Client Hub and your clients can instantly resolve uncategorized transactions.</p>
                    <!-- <div class="hero-buttons">
                      <div>
                        <div dropdown placement="bottom right"
                    style="position: relative; font-size: 16px; color: #0075FF; cursor: pointer">
                          <div dropdownToggle>
                            <button class="btn btn-primary-light">View Demo <i class="feather chevron-down"></i></button>
                          </div>
                          <ul *dropdownMenu class="dropdown-menu" role="menu">
                              <li role="menuitem">
                                  <a (click)="openModal(demoModal)">Watch it now</a>
                              </li>
                              <li role="menuitem">
                                  <a (click)="openModal(demoScheduleModal)">Schedule meeting</a>
                              </li>
                          </ul>
                        </div>
                      </div>
                      <div class="or">Or</div>
                      <div>
                    </div>
                </div> -->
                <button class="btn btn-primary" style="padding: 11px 22px" (click)="startRegistration()">Get a Demo</button>
                    <p style="color: #151C29; font-size: 16px; margin-top: 16px; font-weight: 500; margin-bottom: 0;">14 day trial; No credit card required.</p>
                  </div>
                </div>
            </div>
        </div>
    </section>

    <!-- <section id="features">
        <div class="container">
            <div class="section-title">
                <h2 class="text-center">All-in-One Client Collaboration Platform <br>that Clients Actually Love to Use</h2>
            </div>
            <div class="row flex-row flex-center">
                <div class="col-sm-4">
                    <div class="feature-item">
                        <div class="icon">
                            <img src="assets/img/quickbooks-landing-page/simple-communication.svg" alt="Simple Communication Icon">
                        </div>
                        <div class="description">
                            Simple Communication
                        </div>
                    </div>
                    <div class="feature-item">
                        <div class="icon">
                            <img src="assets/img/quickbooks-landing-page/task-management.svg" alt="Task Management Icon">
                        </div>
                        <div class="description">
                            Task Management
                        </div>
                    </div>
                    <div class="feature-item">
                        <div class="icon">
                            <img src="assets/img/quickbooks-landing-page/secure-file-sharing.svg" alt="Secure File Sharing Icon">
                        </div>
                        <div class="description">
                            Secure File Sharing
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="feature-item">
                        <div class="icon">
                            <img src="assets/img/quickbooks-landing-page/access-apps-and-resources.svg" alt="Access Apps and Resources Icon">
                        </div>
                        <div class="description">
                            Access Apps and Resources
                        </div>
                    </div>
                    <div class="feature-item">
                        <div class="icon">
                            <img src="assets/img/quickbooks-landing-page/quickbooks-integration.svg" alt="QuickBooks Integration Icon">
                        </div>
                        <div class="description">
                            QuickBooks Integration
                        </div>
                    </div>
                    <div class="feature-item">
                        <div class="icon">
                            <img src="assets/img/quickbooks-landing-page/and-more.svg" alt="And More Icon">
                        </div>
                        <div class="description">
                            And more...
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div>
                        <img class="img-responsive" src="assets/img/quickbooks-landing-page/workspace.png" alt="Workspace Icon">
                    </div>
                    <p class="text-center">
                        All this enabled through a simple Client Workspace — accessed online and with our mobile apps.
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section id="testimonials">
        <div class="container">
            <div class="row flex-row flex-center">
                <div class="col-sm-4">
                    <div class="testimonial-card">
                        <p>We implemented Client Hub about 6 months ago and it was one of the best decisions we’ve made.</p>
                        <img src="assets/img/quickbooks-landing-page/totally-booked.png" alt="Totally Booked Logo">
                        <div class="person">
                            <div class="name">Kelly Gonsalves</div>
                            <div class="company">Totally Booked</div>
                            <div class="city">New York, NY</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="testimonial-card">
                        <p>We recently acquired a new client because we use Client Hub! An existing client was so happy with Client Hub that she decided to show it off to a friend who also owns her own business.</p>
                        <img src="assets/img/quickbooks-landing-page/main-accounting-services.png" alt="Main Accounting Services Logo">
                        <div class="person">
                            <div class="name">Angela Roberts</div>
                            <div class="company">Main Accounting Services</div>
                            <div class="city">Kennesaw, GA</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="testimonial-card">
                        <p>We absolutely love being able to reach our construction clients on their phones while they are out and about.</p>
                        <img src="assets/img/quickbooks-landing-page/automated-accounting-services.png" alt="Automated Accounting Services Logo">
                        <div class="person">
                            <div class="name">Monique Swanson</div>
                            <div class="company">Automated Accounting Services</div>
                            <div class="city">Merrimac, MA</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <section id="how-does-it-work">
        <div class="container text-center">
            <div class="section-title">
                <h2>How does it work?</h2>
            </div>

            <h3 class="text-center">
                Getting Going:
            </h3>
            <div class="row mb">
                <div class="col-sm-4 has-arrow">
                    <img src="assets/img/quickbooks-landing-page/sign-up-for-client-hub.svg" alt="Sign Up for Client Hub Icon">
                    <h4>Sign Up for Client Hub</h4>
                    <p>Your firm will be ready to go in just a few seconds.</p>
                </div>
                <div class="col-sm-4 has-arrow">
                    <img src="assets/img/quickbooks-landing-page/invite-clients.svg" alt="Invite Clients Icon">
                    <h4>Invite Clients</h4>
                    <p>Each client gets their own workspace that they access either online or through our mobile app.</p>
                </div>
                <div class="col-sm-4">
                    <img src="assets/img/landing-page-v4/comparisons/qbo-integration.svg" alt="Connect To QuickBooks Icon">
                    <h4>Connect To QuickBooks</h4>
                    <p>Connect their Client Hub workspace to their QuickBooks file and choose their “uncategorized account”.</p>
                </div>
            </div>
            
            <h3 class="text-center">
                Ongoing QuickBooks Integration — It’s Auto-Magical:
            </h3>
            <div class="row">
                <div class="col-sm-4 has-arrow">
                    <img src="assets/img/quickbooks-landing-page/uncategorized-expense-created.svg" alt="Uncategorized Expense Created Icon">
                    <h4>Uncategorized Expense Created</h4>
                    <p>In QuickBooks, just save an expense to the uncategorized expense account as you usually do.</p>
                </div>
                <div class="col-sm-4 has-arrow">
                    <img src="assets/img/quickbooks-landing-page/client-task-sent.svg" alt="Client Task sent Icon">
                    <h4>Client Task sent</h4>
                    <p>Client Hub will immediately send a Client Task to the client with the details of the expense and requests them to comment and/or categorize.</p>
                </div>
                <div class="col-sm-4">
                    <img src="assets/img/quickbooks-landing-page/transaction-updated.svg" alt="Transaction Updated Icon">
                    <h4>Transaction Updated</h4>
                    <p>When client has responded, you simply finalize the new categorization and the transaction is automatically updated in QuickBooks!</p>
                </div>
            </div>
        </div>
    </section>

    <!-- <section id="pricing" class="text-center">
        <div class="container">
            <div class="row d-flex align-items-center">
                <div class="col-md-4 text-left">
                    <div class="section-title">
                        <h5>Pricing</h5>
                        <h2 class="text-center">No Risk Trial.<br>No Credit Card Required.</h2>
                        <p class="text-center">
                          <strong style="color:#151C29">Start Free Trial Now</strong><br>
                          14 Day Free Trial - Pro level.<br>Monthly Billing after Trial.<br>Cancel Anytime.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="pricing-box">
                        <div class="title">
                            Standard
                            <div class="subtitle">Monthly Plan</div>
                        </div>
                        <div class="description">Complete client collaboration solution</div>
                        <div class="pricing-first-user">$35 / first firm user </div>
                        <div class="pricing-other-users">+ <div class="price">$20</div> / additional firm user</div>
                        <div style="margin-top: -8px;">No charge for client users</div>
                        <ul class="features-list text-left">
                            <li><div class="check-icon"></div><span>Unlimited clients</span></li>
                            <li><div class="check-icon"></div><span>Unlimited client users</span></li>
                            <li><div class="check-icon"></div><span>Track client tasks</span></li>
                            <li><div class="check-icon"></div><span>Client communication</span></li>
                            <li><div class="check-icon"></div><span>Client file sharing</span></li>
                            <li><div class="check-icon"></div><span>Manage Internal files</span></li>
                            <li><div class="check-icon"></div><span>Mobile app access</span></li>
                            <li><div class="check-icon"></div><span>And much, much more …</span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="pricing-box" style="box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.2)">
                        <div class="title" style="font-size:32px">
                            Pro
                            <div class="subtitle">Monthly Plan</div>
                        </div>
                        <div class="description">+ Auto-Create Client Tasks for QuickBooks</div>
                        <div class="pricing-first-user">$45 / first firm user </div>
                        <div class="pricing-other-users">+ <div class="price">$30</div> / additional firm user</div>
                        <div style="margin-top: -8px;">No charge for client users</div>
                        <ul class="features-list text-left">
                            <li><div class="check-icon"></div><span>Everything in Standard</span></li>
                            <li>
                                <div class="check-icon"></div>
                                <span style="font-weight: 600">+ Automatically send Client Tasks from QuickBooks uncategorized expense transactions</span>
                            </li>
                        </ul>
                        <button (click)="startRegistration()" class="btn btn-primary btn-lg btn-block">Start Free Trial Now</button>
                        <p style="font-size: 17px; font-weight:500; margin-top: 15px;">14-Day Free Trial (Pro plan)</p>
                        <p style="font-size: 15px; margin-top: -10px; font-weight: bold;">Choose Standard or Pro at end of trial</p>
                    </div>
                </div>
            </div>

        </div>
    </section> -->
</main>
<app-footer></app-footer>

<ng-template #videoModal class="modal-lg">
    <div class="modal-header">
        <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
                class="feather x"></i></button>
        <h4 class="modal-title" id="myModalLabel">QuickBooks Integration</h4>
    </div>
    <div class="modal-body"
        style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/OkHr0c49SuA?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</ng-template>

<ng-template #demoModal class="modal-lg">
    <div class="modal-header">
        <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
                class="feather x"></i></button>
        <h4 class="modal-title" id="myModalLabel">Watch it now</h4>
    </div>
    <div class="modal-body"
        style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
        <iframe style="width:100%;height:100%;"
            src="https://pipedrivewebforms.com/form/c8e66402a250d860e9fb210c117b566b5203832" frameborder="0"></iframe>
    </div>
</ng-template>

<ng-template #demoScheduleModal class="modal-lg">
    <div class="modal-header">
        <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
                class="feather x"></i></button>
        <h4 class="modal-title" id="myModalLabel">Schedule meeting</h4>
    </div>
    <div class="modal-body"
        style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
        <iframe style="width:100%;height:100%;"
            src="https://calendly.com/judiech/discussion?month=2020-01" frameborder="0"></iframe>
    </div>
</ng-template>
<app-sign-up-drawer [signUpDrawerOpen]="signUpDrawerOpen" (signUpDrawerToggle)="signUpDrawerToggle($event)"></app-sign-up-drawer>