<footer>
    <div class="container footer-top">
        <div class="row">
            <div class="col-sm-6 col-md-3">
                <div class="footer-title">Comparisons</div>
                <a [routerLink]="['/comparisons', 'karbon']">Alternative to Karbon</a>
                <a [routerLink]="['/comparisons', 'jetpack']">Alternative to JetPack</a>
                <!-- <a [routerLink]="['/comparisons', 'senta']">Alternative to Senta</a> -->
                <a [routerLink]="['/comparisons', 'clickup']">Alternative to ClickUp</a>
                <a [routerLink]="['/comparisons', 'asana']">Alternative to Asana</a>
                <a [routerLink]="['/comparisons', 'financial-cents']">Alternative to Financial Cents</a>
                <!-- <a [routerLink]="['/comparisons', 'pixie']">Alternative to Pixie</a> -->
                <a [routerLink]="['/comparisons', 'liscio']">Alternative to Liscio</a>
                <!-- <a href="/#/alternative/jetpack" target="_blank" rel="noreferrer noopener">Alternative to JetPack</a> -->
                <!-- <a href="/#/alternative/karbon" target="_blank" rel="noreferrer noopener">Alternative to Karbon</a> -->
                <!-- <a href="/#/alternative/sharefile" target="_blank" rel="noreferrer noopener">Alternative to ShareFile</a> -->
                <!-- <a href="/#/alternative/smartvault" target="_blank" rel="noreferrer noopener">Alternative to SmartVault</a> -->
                <!-- <a href="/#/alternative/liscio" target="_blank" rel="noreferrer noopener">Alternative to Liscio</a> -->
            </div>
            <div class="col-sm-6 col-md-3">
                <div class="footer-title">Guides</div>
                <a href="/#/all-in-one" target="_blank" rel="noreferrer noopener">All-in-One Practice Platform</a>
                <a href="/#/sophisticated-yet-simple" target="_blank" rel="noreferrer noopener">Sophisticated Yet Simple</a>
                <a href="https://clienthub.app/blog/secure-file-sharing-with-clients/"
                    target="_blank" rel="noreferrer noopener">Sharing Files with Client Hub</a>
                <a href="/#/quickbooks" target="_blank" rel="noreferrer noopener">QuickBooks Integration</a>
                <a href="https://clienthub.app/blog/tag/firm-spotlights/" target="_blank" rel="noreferrer noopener">Firm Spotlights</a>
                <a (click)="openModal(availabilityModal)">Client Hub Service Uptime</a>
            </div>
            <div class="col-sm-6 col-md-3">
                <div class="footer-title">Solutions</div>
                <!-- <a href="#" target="_blank" rel="noreferrer noopener">Practice Management for cloud accountants</a>
              <a href="#" target="_blank" rel="noreferrer noopener">Workflow Management for cloud accountants</a>
              <a href="#" target="_blank" rel="noreferrer noopener">File management for cloud accountants</a>
              <a href="#" target="_blank" rel="noreferrer noopener">Secure Client Portal for cloud accountants</a> -->
                <a href="https://clienthub.app/blog/client-portal-software-for-accountants/" target="_blank" rel="noreferrer noopener">Client Portal for Accountants</a>
                <a href="https://clienthub.app/blog/secure-file-sharing-with-clients/" target="_blank" rel="noreferrer noopener">Secure File Sharing with Clients</a>
                <a href="https://clienthub.app/blog/accounting-workflow-software/" target="_blank" rel="noreferrer noopener">Accounting Workflow Software</a>
            </div>
            <div class="col-sm-6 col-md-3">
                <div class="footer-title">About</div>
                <a href="https://clienthub.app/blog/" target="_blank" rel="noreferrer noopener">Blog</a>
                <a href="https://www.getrevue.co/profile/clienthub" target="_blank" rel="noreferrer noopener">Cloud App Newsletter</a>
                <a href="https://clienthub.app/#/about-us" target="_blank" rel="noreferrer noopener">About Us</a>
                <a routerLink="/council">Customer Advisory Council</a>
                <address style="margin-top: 32px;">
                    <div>
                        6160 SW Highway 200<br>
                        Suite 110-582, Ocala, FL 34476
                    </div>
                    <div>
                        Phone: +1-352-559-6321<br>
                        Email Us: info at clienthub.app
                    </div>
                </address>
            </div>
        </div>
        <div style="font-size: 12px; padding: 15px 0;">
            Note: QuickBooks and ProAdvisor and trademarks of Intuit Inc. Used with permission under terms of the Intuit developer partner program.
        </div>
        <hr>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-sm-7 terms">
                    <a routerLink="/terms-of-service" style="margin-right: 15px;">Terms of Service</a>
                    <a routerLink="/privacy-policy" style="margin-right: 15px;">Privacy Policy</a>
                    <a routerLink="/data-security">Data Security</a>
                </div>
                <div class="col-sm-5 text-right social">
                    <a href="https://www.facebook.com/ClientHubApp/" target="_blank" rel="noreferrer noopener"><i
                            class="fa fa-facebook-official"></i></a>
                    <a href="https://twitter.com/@clienthubapp" target="_blank" rel="noreferrer noopener"><i class="fa fa-x-twitter"></i></a>
                    <a href="https://www.linkedin.com/company/11359354/" target="_blank" rel="noreferrer noopener"><i
                            class="fa fa-linkedin"></i></a>
                </div>
            </div>
        </div>
    </div>
</footer>

<ng-template #availabilityModal class="modal-lg">
    <div class="modal-header">
        <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"><i
                class="feather x"></i></button>
        <h4 class="modal-title" id="myModalLabel">Availability</h4>
    </div>
    <div class="modal-body"
        style="overflow:hidden;padding:0; height: 87vh; background: url(assets/img/ajax-loader.gif) center center no-repeat;">
        <iframe style="width:100%;height:100%;" src="https://clienthub-609.freshstatus.io/" frameborder="0"></iframe>
    </div>
</ng-template>