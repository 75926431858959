import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { DomSanitizer } from "@angular/platform-browser";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { Router, ActivatedRoute, RouterOutlet } from "@angular/router";
import { Title } from "@angular/platform-browser";

/**
 * Dashboard component. It's the main header after the user is logged in
 */
@Component({
    selector: "app-registration",
    templateUrl: "./registration.component.html",
    styleUrls: ["./registration.component.scss"],
    standalone: true,
    imports: [RouterOutlet],
})
export class RegistrationComponent implements OnInit {
  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    public titleCtrl: Title,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (
      this.localStorage.get("user") !== null &&
      this.localStorage.get("user") !== undefined &&
      this.localStorage.get("data") !== null &&
      this.localStorage.get("data") !== undefined &&
      this.localStorage.get("company") !== null &&
      this.localStorage.get("company") !== undefined
    ) {
      if (this.localStorage.get("data")["user_type"] == 1) {
        this.router.navigate(["/practice/workspaces"]);
      } else {
        this.router.navigate(["/client"]);
      }
    }
  }
}
