import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { HotToastService } from "@ngneat/hot-toast";
import { CreateService } from "src/app/registration/create/create.service";

@Component({
    selector: "app-email-confirmation",
    templateUrl: "./email-confirmation.component.html",
    styleUrls: ["./email-confirmation.component.scss"],
    standalone: true,
})
export class EmailConfirmationComponent implements OnInit {
  constructor(
    private toastr: HotToastService,
    private createService: CreateService,
    private localStorage: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.ensureFreshLogin();

    this.activatedRoute.params.subscribe((params) => {
      if (params["key"] !== undefined) {
        let key = params["key"];
        let decoded = decodeURIComponent(key.split("/"));
        this.createService.emailConfirmation(decoded).then(() => {
          this.toastr.success(`Account activated`);
          this.router.navigate(["/login"]);
        });
      } else {
        // change to CH front page
        this.router.navigate(["/"]);
      }
    });
  }

  ensureFreshLogin() {
    this.localStorage.remove("data");
    this.localStorage.remove("user");
    this.localStorage.remove("company");
    this.localStorage.remove("remember");
    this.localStorage.remove("access_token");
    this.localStorage.remove("refresh_token");
  }
}
