import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { LocalStorageService } from "src/app/utils/localstorage.service";
import { SubscriptionType } from "src/app/dashboard/shared-data.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class CreateService {
  constructor(
    public http: HttpClient,
    public localStorageService: LocalStorageService
  ) {}

  add_user(
    first_name,
    last_name,
    email,
    password,
    user_type,
    access_level,
    company_id,
    invite_id
  ): Promise<any> {
    const data = {
      username: email,
      email: email,
      first_name: first_name,
      last_name: last_name,
      password: password,
      user_type: user_type,
      access_level: access_level,
      company_id: company_id,
      invite_id: invite_id,
    };
    return this.http
      .post(`${environment.apiUrl}/user/create`, data)
      .toPromise();
  }

  add_company(
    name,
    handle,
    is_accounting,
    invite_id,
    type: SubscriptionType = SubscriptionType.PREMIUM,
    confirmation_pending = false,
    short_trial = false
  ): Promise<any> {
    const data = {
      name: name,
      handle: handle,
      owner: this.localStorageService.get("user")["id"],
      is_accounting: true,
      invite_id: invite_id,
      type: type,
      confirmation_pending,
      short_trial,
    };

    // console.log(data);
    return this.http.post(`${environment.apiUrl}/company/`, data).toPromise();
  }

  emailConfirmation(key: string): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/company/confirm-email/${key}`)
      .toPromise();
  }
}
