<main id="start" class="fake-body">

  <section id="podcast">
    <div class="container">
      <div class="podcast-logos">
        <div class="logo-ch">
          <img src="assets/img/logo-full-white.svg">
        </div>
        <div class="plus">+</div>
        <div class="logo-podcast">
          <img src="assets/img/cloud-logo.png">
        </div>
      </div>
  
      <h1 class="text-center">
        Enjoy the Cloud Accounting Podcast? <br>
        You’ll Love Client Hub
      </h1>

      <p style="font-size: 20px; margin-bottom: 32px; color: rgba(255,255,255,.8) !important;" class="text-center">Modern cloud accounting firms love Client Hub.  It’s a uniquely productive platform to run your firm with workflow and client collaboration brought together in one solution.</p>
      <h2 class="text-center" style="margin-bottom: 16px;">We Guarantee You’ll Love It</h2>
      <p style="font-size: 20px; color: rgba(255,255,255,.8) !important;" class="text-center">Sign up and use <strong tooltip="Copy to clipboard" style="padding: 0 5px; border: 2px dashed #fff; color: #FFFF00 !important; border-radius: 8px; cursor: pointer;" (click)="copyCode()">CAP25 <i class="feather clipboard" style="vertical-align: -1px;"></i></strong> to get 25% off your first 3 months.<br>
        And if you’re not satisfied anytime during these 3 months, drop us a note and we’ll issue you a refund 
      </p>
    </div>
  </section>

  <section id="hero" class="hero first-hero">
    <div class="container">
      <div class="row flex-row flex-center">
        <div class="col-md-6 flex-grow-mob">
          <div class="animated fadeInUp delay-1">
            <div class="announcement" style="margin-bottom: 75px;">
              <div class="text">No More QuickBooks Uncategorized Expenses!</div>
              <a href="/#/quickbooks" class="btn btn-success">Learn More</a>
            </div>
          </div>
          <div class="hero-text-wrapper">
            <h1 class="animated fadeInUp delay-2">How Cloud Accountants run their firm in a uniquely productive way</h1>
            <h2 style="color: #485364" class="animated fadeInUp delay-3 description">Typical workflow systems are built for internal teams only, leaving you waiting for client responses.<br>Introducing Client Hub, the one and only workflow solution with a client collaboration portal that automates client requests for everything you need to complete your work.</h2>
            <form class="get-started-form animated fadeInUp delay-4">
              <button class="btn btn-primary" (click)="startRegistration()">Get a Demo</button>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <div id="parallax-scene" data-limit-y="1">
            <div data-depth="0.05">
                <img class="img-responsive" src="assets/img/landing-page-v4/hero/green-circle.svg">
            </div>
            <div data-depth="0.1">
                <img class="img-responsive" src="assets/img/landing-page-v4/hero/purple-circle.svg">
            </div>
            <div data-depth="0.35">
                <img class="img-responsive" src="assets/img/landing-page-v4/hero/man.png">
            </div>
            <div data-depth="0.35">
                <img class="img-responsive" src="assets/img/landing-page-v4/hero/firm-and-client-experience.svg">
            </div>
            <div data-depth="0.45">
                <img class="img-responsive" src="assets/img/landing-page-v4/hero/woman.png">
            </div>
            <div data-depth="0.5">
                <img class="img-responsive" src="assets/img/landing-page-v4/hero/firm-experience.svg">
            </div>
        </div>
        </div>
      </div>
    </div>
  </section>

  <section id="proadvisors" class="animated fadeInUp delay-5" style="min-height: auto">
    <div class="container">
      <div class="section-title text-center">
        <h2>Why Cloud Accountants Love Client Hub</h2>
        <p>And check out their <a href="https://quickbooks.intuit.com/app/apps/appdetails/ClientHub/en-us/"
            target="_blank" rel="noreferrer noopener">unfiltered reviews</a>.</p>
      </div>

      <div class="row d-flex text-center">
        <div class="col-md-4">
          <img class="responsive-img" src="assets/img/landing-page-v4/client-experience.svg" alt="">
          <h3>Client Experience Built-in</h3>
          <p>No more workflows blocked because clients aren’t getting back to you. Client communication in our web and
            mobile apps (eliminating cluttered email boxes) changes the game.</p>
        </div>
        <div class="col-md-4">
          <img class="responsive-img" src="assets/img/landing-page-v4/all-in-one-platform.svg" alt="">
          <h3>All-in-One Platform</h3>
          <p>No more complex tech stack. Adopt Client Hub as a one-stop-shop for workflow, client communication, client
            portal, file sharing, and more.</p>
        </div>
        <div class="col-md-4">
          <img class="responsive-img" src="assets/img/landing-page-v4/automation.svg" alt="">
          <h3>Automation Everywhere</h3>
          <p>Set jobs to recur. Automatically kick off Client Tasks and automatically remind when overdue. And our
            favorite, automatically create Client Tasks from QuickBooks uncategorized transactions.</p>
        </div>
      </div>

      <!-- <div class="text-center" style="margin-top: 70px">
                <button class="btn btn-primary btn-scale" (click)="startRegistration()">View a Demo</button>
            </div> -->
    </div>
  </section>

  <section id="testimonials">
    <div class="container">
      <div class="section-title text-center">
        <h2>Don’t Just Take Our Word for It</h2>
      </div>

      <div class="row fix-mobile">
        <div class="col-lg-4">
          <h5>OUR UNFILTERED REVIEWS</h5>
          <div class="testimonial d-flex align-items-center">
            <a target="_blank" rel="noreferrer noopener" href="https://www.capterra.com/p/180857/Client-Hub/"><img
                alt="Capterra Best Value for Collaboration Nov-2020" title="Best Value Badge"
                style="width: 180px; border: 0px"
                src="https://cdn0.capterra-static.com/assets/images/gdm-badges/CAP_CrowdBadge_BestValue_Full-Color.png"></a>
          </div>
        </div>
        <div class="col-lg-4">
          <h5>THOUGHT-LEADERS TOO</h5>
          <div class="testimonial d-flex align-items-center has-more">
            <carousel [noPause]="false">
              <slide>
                <div class="testimonial-avatar-name">
                  <div class="testimonial-avatar" style="background: url(assets/img/landing-page-v3/clayton.jpg)"></div>
                  <div class="testimonial-name">
                    Clayton Oates<br>
                    <a href="http://claytonoates.com/" target="_blank" rel="noreferrer noopener">QA Business</a>
                  </div>
                </div>
                <div class="testimonial-text">
                  What impresses me about Client Hub is its simplicity, practical design and
                  that it is a client facing solution.
                </div>
              </slide>
              <slide>
                <div class="testimonial-avatar-name">
                  <div class="testimonial-avatar" style="background: url(assets/img/landing-page-v3/Jan-Haugo.jpg)">
                  </div>
                  <div class="testimonial-name">
                    Jan Haugo<br>
                    <a href="https://www.jhaccounting.net/" target="_blank" rel="noreferrer noopener">Jan Haugo &
                      Associates</a>
                  </div>
                </div>
                <div class="testimonial-text">
                  This is the app that I have been waiting for - It centralizes communication
                  and
                  focus on the products I endorse for my client. Along with allowing me to
                  brand
                  and
                  look like the hero in their eyes.
                </div>
              </slide>
              <slide>
                <div class="testimonial-avatar-name">
                  <div class="testimonial-avatar"
                    style="background: url(assets/img/landing-page-v3/Heather-Satterley.jpg)">
                  </div>
                  <div class="testimonial-name">
                    Heather Satterley<br>
                    <a href="https://satterleyconsulting.com/" target="_blank" rel="noreferrer noopener">Satterley
                      Training &
                      Consulting</a>
                  </div>
                </div>
                <div class="testimonial-text">
                  Client Hub was so easy to set up, it took less than 10 minutes. The first
                  client
                  I
                  showed it to loved it. It’s exactly what she has been asking for.
                </div>
              </slide>
              <slide>
                <div class="testimonial-avatar-name">
                  <div class="testimonial-avatar" style="background: url(assets/img/landing-page-v3/blake.png)">
                  </div>
                  <div class="testimonial-name">
                    Blake Oliver<br>
                    <a href="https://www.blakeoliver.com/" target="_blank" rel="noreferrer noopener">blakeoliver.com</a>
                  </div>
                </div>
                <div class="testimonial-text">
                  I’ve dreamed about building something just like this that can solve these
                  exact pain points. Bravo to you (Client Hub) for actually doing it.
                </div>
              </slide>
              <slide>
                <div class="testimonial-avatar-name">
                  <div class="testimonial-avatar" style="background: url(assets/img/landing-page-v3/kenji.jpg)"></div>
                  <div class="testimonial-name">
                    Kenji Kuramoto<br>
                    <a href="https://acuity.co/" target="_blank" rel="noreferrer noopener">Acuity</a>
                  </div>
                </div>
                <div class="testimonial-text">
                  Client Hub is going to provide a much better communication experience
                  between accounting firms and their clients.
                </div>
              </slide>
              <slide>
                <div class="testimonial-avatar-name">
                  <div class="testimonial-avatar" style="background: url(assets/img/landing-page-v3/will.jpg)">
                  </div>
                  <div class="testimonial-name">
                    Will Lopez<br>
                    <a href="http://advisorfi.com/" target="_blank" rel="noreferrer noopener">AdvisorFi</a>
                  </div>
                </div>
                <div class="testimonial-text">
                  Client Hub has a firm to user experience that is simple, easy to get up and
                  running, but powerful.
                </div>
              </slide>
              <slide>
                <div class="testimonial-avatar-name">
                  <div class="testimonial-avatar" style="background: url(assets/img/landing-page-v3/doug.png)">
                  </div>
                  <div class="testimonial-name">
                    Doug Sleeter<br>
                    <a href="https://www.sleeter.com/" target="_blank" rel="noreferrer noopener">Sleeter Group</a>
                  </div>
                </div>
                <div class="testimonial-text">
                  Client Hub is going to revolutionize the way that accounting practices work
                  with clients.
                </div>
              </slide>
            </carousel>
          </div>
        </div>
        <div class="col-lg-4">
          <h5>EVEN THE AICPA…</h5>
          <div class="testimonial d-flex align-items-center">
            <img src="assets/img/cpa.png" class="img-responsive">
          </div>
        </div>
      </div>

      <div class="text-center" style="margin-top: 70px">
        <button class="btn btn-primary btn-scale" (click)="startRegistration()">Get a Demo</button>
      </div>
    </div>
  </section>

  <section id="set-of-features">
    <div class="container">
      <div class="section-title text-center">
        <h2>Just the Right Set of Features</h2>
        <p>We are designed specifically for cloud accounting and bookkeeping practices — and we are single-mindedly
          focused on features that drive a frictionless workflow for your needs.</p>
      </div>
      <div class="features-items">
        <div class="row">
          <div class="col-md-4">
            <img src="assets/img/landing-page-v4/simples-workflow.svg" alt="Simple Workflow icon">
            <h3>Simple Workflow</h3>
            <p>Recurring jobs, task checklists, Simple yet powerful work dashboard.</p>
          </div>
          <div class="col-md-4">
            <img src="assets/img/landing-page-v4/secure-file-sharing.svg" alt="Secure File Sharing icon">
            <h3>Secure File Sharing</h3>
            <p>Exchange files via mobile or drag-and-drop, organize via client-facing and internal folders for each
              client.</p>
          </div>
          <div class="col-md-4">
            <img src="assets/img/landing-page-v4/modern-client-portal.svg" alt="Modern Client Portal icon">
            <h3>Modern Client Portal</h3>
            <p>A client-facing workspace that includes messages, client tasks, files, and self-service apps and
              resources.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <img src="assets/img/landing-page-v4/quickbooks-reclassification.svg"
              alt="Automated QuickBooks Reclassification icon">
            <h3>Automated QuickBooks Reclassification</h3>
            <p>Connect your client’s QuickBooks company and automatically send them a Client Task whenever an expense is
              posted to an unclassified account.</p>
          </div>
          <div class="col-md-4">
            <img src="assets/img/landing-page-v4/client-communication.svg" alt="Frictionless Client Communication icon">
            <h3>Frictionless Client Communication</h3>
            <p>Eliminate email siloes and manage client communication in the same system where you manage your work.</p>
          </div>
          <div class="col-md-4">
            <img src="assets/img/landing-page-v4/mobile-app.svg" alt="Awesome Mobile App for Android & iOS icon">
            <h3>Awesome Mobile App for Android & iOS</h3>
            <p>Have your clients who are out and about use our mobile app to connect with you (and your team can use it
              too).</p>
          </div>
        </div>
      </div>
      <div class="big-cta teal">
        <div class="row">
          <div class="col-sm-3 col-md-3 cta-illustration">
            <img src="assets/img/landing-page-v4/billy-cta-1.png" alt="Image of an accountant">
          </div>
          <div class="col-sm-5 col-md-6">
            <div class="title">Ready to Put All-in-One to Work in Your Practice?</div>
          </div>
          <div class="col-sm-3 col-md-3 text-right">
            <button class="btn btn-teal" (click)="startRegistration()">Get a Demo</button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="pricing" style="min-height: auto">
    <div class="container text-center">
        <div class="section-title text-center">
            <h2>Simple Pricing</h2>
            <p>Like everything else about us, our pricing is drop-dead simple.  <br>
                No set up fees. No minimum terms. No gimmicks.
                </p>
        </div>
        <!-- <div class="row d-flex align-items-center">
            <div class="col-sm-6 col-md-6">
                <div class="pricing-table blue">
                    <div class="plan-logo">
                        <img src="assets/img/landing-page-v4/ch-standard-plan.svg" alt="Client Hub Standard Plan">
                    </div>
                    <div class="plan-description">
                        <strong>Client Collaboration</strong> Solution including:<br>Communication, Client Tasks and Files
                    </div>
                    <div class="pricing-section">
                        <div class="top">$35 for first firm user/month</div>
                        <div class="middle">
                            <div class="plus">+</div>
                            <div class="price-big">$20</div>
                            <div class="price-descriptio">per additional<br>firm user/month</div>
                        </div>
                        <div class="bottom">Free for all Client Users </div>
                    </div>
                    <div class="plan-features">
                        <ul>
                            <li>Simple client messaging</li>
                            <li>Assign and track client tasks</li>
                            <li>QuickBooks categorization tasks</li>
                            <li>Secure client file-sharing</li>
                            <li>Manage Internal files</li>
                            <li>Client apps & resources</li>
                            <li>Mobile app access</li>
                            <li>Your firm branding</li>
                            <li>And much, much more…</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6">
                <div class="pricing-table green">
                    <div class="plan-logo">
                        <img src="assets/img/landing-page-v4/ch-pro-plan.svg" alt="Client Hub Pro Plan">
                    </div>
                    <div class="plan-description">
                        All-in-One: <strong>Client Collaboration<br>and Internal Workflow</strong> Solution
                    </div>
                    <div class="pricing-section">
                        <div class="top">$45 for first firm user/month</div>
                        <div class="middle">
                            <div class="plus">+</div>
                            <div class="price-big">$30</div>
                            <div class="price-descriptio">per additional<br>firm user/month</div>
                        </div>
                        <div class="bottom">Free for all Client Users </div>
                    </div>
                    <div class="plan-features">
                        <ul>
                            <li>Everything in Standard</li>
                            <li class="plus">Track work with Jobs </li>
                            <li class="plus">Set up recurring Jobs</li>
                            <li class="plus">Checklist of tasks for each job</li>
                            <li class="plus">Rich task details with video/media</li>
                            <li class="plus">Visual Jobs Dashboard</li>
                        </ul>
                    </div>

                    <button class="btn btn-success btn-scale">View a Demo</button>
                    <button class="btn btn-success btn-scale" (click)="startRegistration()">Try It Free Now</button>

                    <div class="plan-obs">
                        <div class="title">14-Day Free Trial with Pro features</div>
                        <div class="title">No Credit Card Required</div>
                        <div class="description">Decide on Standard / Pro at end of trial</div>
                    </div>
                </div>
            </div>
        </div> -->
        <div>
          <tabset type="pills">
            <img class="three-months-free" src="assets/img/3-months-free.svg">

                <tab heading="Annual Billing">
                    <div id="pricing">
                        <div class="row d-flex align-items-center">
                            <div class="col-sm-6 col-md-7 col-lg-6">
                                <div class="pricing-table green">
                                    <div class="plan-logo">
                                        <h2>ALL-IN-ONE</h2>
                                    </div>
                                    <div class="pricing-section">
                                        <div class="middle">
                                            <div class="plus"></div>
                                            <div class="price-big">$32</div>
                                            <div class="price-descriptio">per user<br>per month</div>
                                        </div>
                                        <div class="bottom">All Features Included</div>
                                    </div>
                                    <div class="plan-features">
                                        <div class="row">
                                            <div class="col-md-6">
                                              <ul>
                                                  <li class="green">Firm Workflow</li>
                                                  <li class="green">Client Communication</li>
                                                  <li class="green">Client Tasks</li>
                                                  <li class="green">Manage and share files</li>
                                                  <li class="green">Dashboards</li>
                                              </ul>
                                            </div>
                                            <div class="col-md-6">
                                              <ul>
                                                  <li class="green">Templates</li>
                                                  <li class="green">Integrations</li>
                                                  <li class="green">Mobile apps</li>
                                                  <li class="no-list">And much more …</li>
                                              </ul>
                                            </div>
                                          </div>
                                    </div>
        
                                    <button class="btn btn-success btn-scale" (click)="startRegistration()">Get a Demo</button>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-5 col-lg-6">
                              <h1 style="margin-bottom: 10px">14-Day Free Trial</h1>
                              <h3 style="margin-bottom: 24px;">No Credit Card Required</h3>
                              <h3 style="line-height: 1.5;">Only your firm’s internal staff count as users.<br>Invite all your clients to use Client Hub <span class="textmarker">at no cost</span>.</h3><br>
                              <h4>Deciding whether to try?</h4>
                              <p>Check out our totally <a href="https://quickbooks.intuit.com/app/apps/appdetails/ClientHub/en-us/" target="_blank" rel="noreferrer noopener">unfiltered reviews</a>.</p>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab heading="Monthly Billing">
                    <div id="pricing">
                        <div class="row d-flex align-items-center">
                            <div class="col-sm-6 col-md-7 col-lg-6">
                                <div class="pricing-table blue">
                                    <div class="plan-logo">
                                        <h2>ALL-IN-ONE</h2>
                                    </div>
                                    <div class="pricing-section">
                                        <div class="middle">
                                            <div class="plus"></div>
                                            <div class="price-big">$39</div>
                                            <div class="price-descriptio">per user<br>per month</div>
                                        </div>
                                        <div class="bottom">All Features Included</div>
                                    </div>
                                    <div class="plan-features">
                                        <div class="row">
                                            <div class="col-md-6">
                                              <ul>
                                                  <li class="">Firm Workflow</li>
                                                  <li class="">Client Communication</li>
                                                  <li class="">Client Tasks</li>
                                                  <li class="">Manage and share files</li>
                                                  <li class="">Dashboards</li>
                                              </ul>
                                            </div>
                                            <div class="col-md-6">
                                              <ul>
                                                  <li class="">Templates</li>
                                                  <li class="">Integrations</li>
                                                  <li class="">Mobile apps</li>
                                                  <li class="no-list">And much more …</li>
                                              </ul>
                                            </div>
                                          </div>
                                    </div>
        
                                    <button class="btn btn-success btn-primary btn-scale" (click)="startRegistration()">Get a Demo</button>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-5 col-lg-6">
                              <h1 style="margin-bottom: 10px">14-Day Free Trial</h1>
                              <h3 style="margin-bottom: 24px;">No Credit Card Required</h3>
                              <h3 style="line-height: 1.5;">Only your firm’s internal staff count as users.<br>Invite all your clients to use Client Hub <span class="textmarker">at no cost</span>.</h3><br>
                              <h4>Deciding whether to try?</h4>
                              <p>Check out our totally <a href="https://quickbooks.intuit.com/app/apps/appdetails/ClientHub/en-us/" target="_blank" rel="noreferrer noopener">unfiltered reviews</a>.</p>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>
       
        </div>
    </div>
</section>

  <section id="faq">
    <div class="container">
      <div class="section-title text-center">
        <h2>Frequently Asked Questions</h2>
      </div>

      <accordion [isAnimated]="true">
        <accordion-group [isOpen]="true" heading="Why do modern cloud accountants choose Client Hub?">
          <ul>
            <li><a href="/#/all-in-one">What we offer</a> — An all-in-one workflow and client communication platform
              that includes unique capabilities such as an award-winning QuickBooks integration to resolve uncategorized
              transactions.</li>
            <li><a href="/#/sophisticated-yet-simple">How we do it</a> — Just try us and you’ll appreciate our ethos of
              “Sophisticated yet Simple” end-to-end experience.</li>
            <li><a href="/#/about-us">Who we are</a> — We are from the profession and self-funded so we are answerable
              only to you, our accountant customers.</li>
          </ul>
        </accordion-group>
        <accordion-group heading="How can you help my firm adopt Client Hub?">
          <p>Yes, once you subscribe, you will have the opportunity to set up a “Success Plan Meeting” with our team and
            your staff where we will review your objectives with Client Hub and set up a plan for achieving them. We can
            even help you with client adoption or migration from existing solutions.</p>
        </accordion-group>
        <accordion-group heading="How can you help my clients adopt Client Hub?">
          <p>As with managing any change, there are strategies you can apply to help clients make the transition to
            communicating with your firm through Client Hub. We have learned a lot over the years working with our
            accounting practice customers on this and can support you in onboarding your clients with resources such as:
          </p>
          <ul>
            <li>Client Onboarding Game Plan -- accessed right in Client Hub </li>
            <li>Templates for client onboarding emails, presentations and videos</li>
            <li>Self-serve Resources that can be added to your client’s Client Hub workspace</li>
          </ul>
        </accordion-group>
        <accordion-group heading="Will my clients adopt a new app to communicate with me?">
          <p>So often we hear “my clients are never going to give up email”. So why do you think that is the case? More
            than likely it's because email is what they know, what they feel comfortable with.</p>
          <ul>
            <li>What if they knew the security pitfalls of email? </li>
            <li>What if they have a hate/hate relationship with their inbox (just like you)? </li>
            <li>What if they would prefer a better, more mobile-friendly way to communicate with you and your team?</li>
          </ul>
          <p>Client Hub gives you the tools to not only be internally efficient, but to help you build deeper and more
            meaningful relationships with your clients. As a trusted advisor they look to you for information and
            advice. You are empowered to lead them to a better way of working collaboratively with your team -- and as
            mentioned above, we can help you drive this change.</p>
        </accordion-group>
        <accordion-group heading="Who else is using Client Hub?">
          <p>Client Hub has been serving cloud accounting firms and their clients around the world since we were
            launched in 2018.</p>
          <p>We have several firm profiles featured in our <a href="https://blog.clienthub.me/"
              target="_blank" rel="noreferrer noopener">blog</a>, as well as reviews on the 3rd-party platform <a
              href="https://www.capterra.com/p/180857/Client-Hub/" target="_blank" rel="noreferrer noopener">Capterra</a>.</p>
        </accordion-group>
        <accordion-group heading="What else can give me confidence to evaluate Client Hub?">
          <p>The AICPA and CPA.com selected Client Hub for their “startup accelerator” program in 2019 as a company
            “that can transform the profession”.</p>
          <p>Our revolutionary <a href="/#/quickbooks">Client Hub Integration with QuickBooks Online</a> was recognized
            as a Finalist of the <a
              href="https://www.cpapracticeadvisor.com/accounting-audit/article/21147925/2020-innovation-award-winners-announced"
              target="_blank" rel="noreferrer noopener">2020 CPA Practice Advisor Technology Innovation Award</a>.</p>
          <p>We are also very grateful to many thought leaders in the accounting industry who have evaluated our
            solution and found it so compelling that they have agreed to be quoted on our main landing page.</p>
        </accordion-group>
        <accordion-group heading="How is my data secured?">
          <p>Security is of primary importance at Client Hub, and we run a highly secure cloud service that is described
            <a href="https://clienthub.app/#/data-security">here</a>. By leveraging Client Hub instead of sharing
            communication and files over email, firms are able to dramatically reduce the risks associated with attacks
            such as phishing and snooping inherent in email communication.</p>
        </accordion-group>
        <accordion-group heading="How can I test and evaluate Client Hub?">
          <p>We offer a 14-day free trial of Client Hub.<br>
            Beyond that, we are so confident that you will love our solution that we don’t lock you into any contracts
            -- you can cancel without risk at any time.
          </p>
        </accordion-group>
        <accordion-group heading="I work with Xero (or other accounting platforms). Can I still use Client Hub?">
          <p>Yes! Our client collaboration and internal workflow solutions are not dependent on any particular client
            accounting platform. Many of our customers use Client Hub effectively with clients who are on Xero,
            Freshbooks or even desktop accounting system. The only difference is that the QuickBooks Online integration
            feature cannot be leveraged when working with these clients.</p>
        </accordion-group>
      </accordion>
    </div>
  </section>

  <section id="testimonials-bottom">
    <div class="container">
      <div class="row flex-row flex-center">
        <div class="col-sm-4">
          <div class="testimonial-card">
            <p>We implemented Client Hub about 6 months ago and it was one of the best decisions we’ve made.</p>
            <img src="assets/img/quickbooks-landing-page/totally-booked.png" alt="Totally Booked Logo">
            <div class="person">
              <div class="name">Kelly Gonsalves</div>
              <div class="company">Totally Booked</div>
              <div class="city">New York, NY</div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="testimonial-card">
            <p>We recently acquired a new client because we use Client Hub! An existing client was so happy with Client
              Hub that she decided to show it off to a friend who also owns her own business.</p>
            <img src="assets/img/quickbooks-landing-page/main-accounting-services.png"
              alt="Main Accounting Services Logo">
            <div class="person">
              <div class="name">Angela Roberts</div>
              <div class="company">Main Accounting Services</div>
              <div class="city">Kennesaw, GA</div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="testimonial-card">
            <p>We absolutely love being able to reach our construction clients on their phones while they are out and
              about.</p>
            <img src="assets/img/quickbooks-landing-page/automated-accounting-services.png"
              alt="Automated Accounting Services Logo">
            <div class="person">
              <div class="name">Monique Swanson</div>
              <div class="company">Automated Accounting Services</div>
              <div class="city">Merrimac, MA</div>
            </div>
          </div>
        </div>
      </div>

      <div class="big-cta orange">
        <div class="row">
          <div class="col-sm-3 col-md-3 cta-illustration">
            <img src="assets/img/landing-page-v4/billy-cta-2.png" alt="Image of an accountant">
          </div>
          <div class="col-sm-5 col-md-6">
            <div class="title">Ready to Put All-in-One to Work in Your Practice?</div>
          </div>
          <div class="col-sm-3 col-md-3 text-right">
            <button class="btn btn-orange" (click)="startRegistration()">Get a Demo</button>
          </div>
        </div>
      </div>
    </div>
  </section>

</main>
<app-footer></app-footer>
<app-sign-up-drawer [signUpDrawerOpen]="signUpDrawerOpen" (signUpDrawerToggle)="signUpDrawerToggle($event)"></app-sign-up-drawer>