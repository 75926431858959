import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-install',
    templateUrl: './app-install.component.html',
    styleUrls: ['../landing-page/landing-page.component.scss', './app-install.component.css'],
    standalone: true,
    imports: [RouterLink]
})
export class AppInstallComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
        this.getMobileOperatingSystem();
    }

    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "windows";
        }

        if (/android/i.test(userAgent)) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.clienthub.clienthub&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
            return "android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.location.href = "https://itunes.apple.com/us/app/client-hub/id1426064081?mt=8";
            return "ios";
        }

        return "unknown";
    }

}
