import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute, RouterLink } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { SignUpDrawerComponent } from "../../sign-up-drawer/sign-up-drawer.component";
import { FooterComponent } from "../../footer/footer.component";
import { NgClass, NgIf } from "@angular/common";
import { NavigationComponent } from "../../navigation/navigation.component";

@Component({
    selector: "app-comparisons",
    templateUrl: "./comparisons.component.html",
    styleUrls: ["../../landing-page.component.scss"],
    standalone: true,
    imports: [
        NavigationComponent,
        NgClass,
        RouterLink,
        NgIf,
        FooterComponent,
        SignUpDrawerComponent,
    ],
})
export class ComparisonsComponent implements OnInit {
  public email: String = "";
  public data: any = this.localStorage.get("data");
  public app: any;
  public appName: any;
  private sub: any;
  public comparisonTab: String = "simple";
  public modalRef: BsModalRef;
  public signUpDrawerOpen: Boolean = false;

  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    private route: ActivatedRoute,
    public modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.app = params["app"];
      this.appName = params["app"]
        .replace("-", " ")
        .replace(/\b\w/g, (l) => l.toUpperCase());
      this.appName == "Clickup" ? (this.appName = "ClickUp") : "";
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

  startRegistration(): void {
    // this.localStorage.set("registration_email", this.email);
    // this.router.navigate(["/registration/join"]);
    this.signUpDrawerToggle(true);
  }

  signUpDrawerToggle(x): void {
    this.signUpDrawerOpen = x;
  }

  chooseApp(app): void {
    this.router.navigate(["/comparisons/" + app]);
  }
}
