import { DashboardComponent } from "./dashboard/dashboard/dashboard.component";
import { LoginModule } from "./login/login.module";
import { BrowserModule } from "@angular/platform-browser";
import { AppInstallComponent } from "./website/app-install.component";
import { PrivacyPoliceComponent } from "./website/privacy-policy.component";
import { TermsOfServiceComponent } from "./website/terms-of-service.component";
import { AboutUsComponent } from "./landing-page/about-us.component";
import { ForgotPasswordComponent } from "./login/forgot-password.component";
import { LoginComponent } from "./login/login.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { NewsletterRedirectComponent } from "./landing-page/newsletter-redirect.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CookiePolicyComponent } from "./website/cookie-policy.component";
import { DataSecurityComponent } from "./website/data-security.component";
import { CommonModule } from "@angular/common";
import { QuickBooksLandingPageComponent } from "./features-landing-pages/quickbooks-landing-page/quickbooks-landing-page.component";
import { SharefileComponent } from "./landing-page/alternatives/sharefile/sharefile.component";
import { SmartvaultComponent } from "./landing-page/alternatives/smartvault/smartvault.component";
import { LiscioComponent } from "./landing-page/alternatives/liscio/liscio.component";
import { BeforeAndAfterPageComponent } from "./landing-page/Pages/before-and-after-page/before-and-after-page.component";
import { ProductTourComponent } from "./landing-page/Pages/product-tour/product-tour.component";
import { AllInOnePracticePlatformComponent } from "./landing-page/Pages/all-in-one-practice-platform/all-in-one-practice-platform.component";
import { WhyChooseUsComponent } from "./landing-page/Pages/why-choose-us/why-choose-us.component";
import { SophisticatedYetSimpleComponent } from "./landing-page/Pages/sophisticated-yet-simple/sophisticated-yet-simple.component";
import { ComparisonsComponent } from "./landing-page/Pages/comparisons/comparisons.component";
import { PodcastComponent } from "./landing-page/podcast/podcast.component";
import { CustomerCouncilComponent } from "./landing-page/Pages/customer-council/customer-council.component";
import { PatriotComponent } from "./landing-page/Pages/patriot/patriot/patriot.component";
import { ScheduleDemoComponent } from "./landing-page/schedule-demo/schedule-demo/schedule-demo.component";
import { TrialDrawerComponent } from "./landing-page/trial-drawer/trial-drawer.component";
import { EmailConfirmationComponent } from "./landing-page/email-confirmation/email-confirmation.component";

const routes: Routes = [
  { path: "quickbooks", component: QuickBooksLandingPageComponent },
  { path: "patriot", component: PatriotComponent },
  { path: "cloud-accounting-podcast", component: PodcastComponent },
  { path: "before-and-after", component: BeforeAndAfterPageComponent },
  { path: "product-tour", component: ProductTourComponent },
  { path: "why-choose-us", component: WhyChooseUsComponent },
  { path: "comparisons/:app", component: ComparisonsComponent },
  { path: "all-in-one", component: AllInOnePracticePlatformComponent },
  { path: "demo", component: ScheduleDemoComponent },
  {
    path: "sophisticated-yet-simple",
    component: SophisticatedYetSimpleComponent,
  },
  { path: "council", component: CustomerCouncilComponent },
  { path: "schedule-demo", component: ScheduleDemoComponent },
  { path: "snh21", component: ScheduleDemoComponent },
  { path: "trial", component: TrialDrawerComponent },
  { path: "email-confirmation/:key", component: EmailConfirmationComponent },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "forgot-password/:uuid", component: ForgotPasswordComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "newsletter", component: NewsletterRedirectComponent },

  { path: "terms-of-service", component: TermsOfServiceComponent },
  {
    path: "terms-and-conditions",
    redirectTo: "terms-of-service",
    pathMatch: "full",
  },

  { path: "privacy-policy", component: PrivacyPoliceComponent },
  { path: "privacy-notice", redirectTo: "privacy-policy", pathMatch: "full" },

  { path: "cookie-policy", component: CookiePolicyComponent },
  { path: "data-security", component: DataSecurityComponent },

  { path: "install", component: AppInstallComponent },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },

  { path: "alternative/sharefile", component: SharefileComponent },
  { path: "alternative/smartvault", component: SmartvaultComponent },
  { path: "alternative/liscio", component: LiscioComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), CommonModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
