import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { SimpleChanges } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { HotToastService } from "@ngneat/hot-toast";
import { SharedDataService } from "src/app/dashboard/shared-data.service";
import { TaggingService } from "src/app/dashboard/tagging.service";
import { LoginService } from "src/app/login/login.service";
import { CreateService } from "src/app/registration/create/create.service";
import { RegistrationService } from "src/app/registration/registration.service";
import { NgxIntlTelInputModule } from "@justin-s/ngx-intl-tel-input";
import { FormsModule } from "@angular/forms";
import { NgClass, NgIf } from "@angular/common";

// declare var gtag: Function;

@Component({
  selector: "app-sign-up-drawer",
  templateUrl: "./sign-up-drawer.component.html",
  styleUrls: ["./sign-up-drawer.component.scss"],
  standalone: true,
  imports: [NgClass, NgIf, FormsModule, NgxIntlTelInputModule, RouterLink],
})
export class SignUpDrawerComponent implements OnInit {
  _isTrial: Boolean = false;

  @Input() signUpDrawerOpen: String;
  @Input() set isTrial(value: Boolean) {
    this._isTrial = value;
    this.drawerState = value ? "trial" : "initial";
  }

  @Output() signUpDrawerToggle = new EventEmitter<boolean>();

  public drawerState = this.isTrial ? "trial" : "initial";
  public teamSize = "";
  accountingCompanyName = "";
  ownerFirstName = "";
  ownerLastName = "";
  ownerEmail = "";
  ownerPhone = "";
  password = "";
  confirmpassword = "";

  lastValidatedEmail = "";
  validEmail = true;
  emailErrorMessage = "";

  ARR_FIRM_SIZE = ["1-2", "3-5", "6-10", "11-25", "26+"];

  /*
    initial: sign up form
    video: when team size is <= 2
    calendar: when team size is >= 3
  */

  constructor(
    public localStorage: LocalStorageService,
    private createService: CreateService,
    private loginService: LoginService,
    private toastr: HotToastService,
    private taggingService: TaggingService,
    public registrationService: RegistrationService,
    public router: Router,
    private sharedDataService: SharedDataService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    changes.signUpDrawerOpen.currentValue
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");

    if (document.getElementById("firstname") != null) {
      document.getElementById("firstname").focus();
    }
    console.log("ngOnChanges");
  }

  ngOnDestroy(): void {
    this.closeDrawer();
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    this.closeDrawer();
  }

  focusFirstName() {
    if (document.getElementById("firstname") != null) {
      document.getElementById("firstname").focus();
    }
  }

  startSignUp() {
    if (this.teamSize != "0" && !this._isTrial) {
      this.drawerState = "calendar";
      // gtag("event", "Demo_requested");
    } else {
      this.drawerState = "calendar";
      // gtag("event", "Demo_requested");
      // if (document.getElementById("firstname") != null) {
      //   document.getElementById("firstname").focus();
      // }
    }
  }

  continue() {
    if (
      this.teamSize == "0" &&
      (this.accountingCompanyName == "" ||
        this.ownerFirstName == "" ||
        this.ownerLastName == "" ||
        this.ownerEmail == "" ||
        this.ownerPhone)
    ) {
      return;
    }
    this.lastValidatedEmail = this.ownerEmail;
    this.registrationService.check_invitation(this.ownerEmail).then(
      (res: any) => {
        if (this.teamSize != "0") {
          this.drawerState = "calendar";
        } else {
          this.taggingService
            .tagAnonymousUser(
              this.ownerEmail,
              this.ownerFirstName,
              this.ownerLastName,
              this.ARR_FIRM_SIZE[this.teamSize],
              this._isTrial ? "trial_flow" : "demo_flow",
              this.ownerPhone,
            )
            .subscribe(() => {});
          this.drawerState = "calendar";
        }
      },
      (err: any) => {
        this.emailErrorMessage = err.error.message;
        this.validEmail = false;
        this.toastr.error(err.error.message);
      },
    );
  }

  continueToPassword() {
    this.drawerState = "password";
  }

  validateEmail(email: string) {
    const validRegex = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    // true = disable the button, so we check the opposite
    return (
      (this.lastValidatedEmail == this.ownerEmail && !this.validEmail) ||
      !validRegex
    );
  }

  validateForm() {
    return (
      this.accountingCompanyName == "" ||
      this.ownerFirstName == "" ||
      this.ownerLastName == "" ||
      this.validateEmail(this.ownerEmail)
    );
  }

  hasCapitalLetter() {
    return String(this.password).match(/([A-Z])/);
  }

  hasLowerCaseLetter() {
    return String(this.password).match(/([a-z])/);
  }

  hasSymbols() {
    return String(this.password).match(/[-!@$%#^&*()_+|~=`{}\[\]:";'<>?,.\/]/);
  }

  hasNumbers() {
    return String(this.password).match(/([0-9])/);
  }

  hasEightChars() {
    return this.password.length >= 8;
  }

  isConfirmPasswordSame() {
    return this.password && this.password == this.confirmpassword;
  }

  validatePassword() {
    return !(
      this.hasCapitalLetter() &&
      this.hasLowerCaseLetter() &&
      this.hasSymbols() &&
      this.hasNumbers() &&
      this.hasEightChars() &&
      this.isConfirmPasswordSame()
    );
  }

  async continueToTrial() {
    if (this.password != this.confirmpassword) {
      return;
    }

    let addUserResponse = await this.createService.add_user(
      this.ownerFirstName,
      this.ownerLastName,
      this.ownerEmail,
      this.password,
      1,
      1,
      null,
      null,
    );

    // gtag("event", "Trial_Started");
    this.toastr.success("User created!");
    this.localStorage.remove("temp_email");
    this.localStorage.remove("temp_first_name");
    this.localStorage.remove("temp_last_name");
    this.localStorage.remove("access_token");
    this.localStorage.remove("refresh_token");
    this.localStorage.remove("data");
    this.localStorage.remove("user");
    this.localStorage.remove("company");
    this.localStorage.remove("pin_expires");

    this.localStorage.set("data", addUserResponse.data);
    this.localStorage.set("user", addUserResponse.user);
    this.localStorage.set("remember", true);

    let loginResponse = await this.loginService.login(
      this.ownerEmail,
      this.password,
    );

    console.log("Login Response", loginResponse);
    this.localStorage.set("access_token", loginResponse.access);
    this.localStorage.set("refresh_token", loginResponse.refresh);
    this.localStorage.set("company", loginResponse.company);
    this.localStorage.set("subscription_type", loginResponse.subscription_type);

    let addCompanyResponse = await this.createService.add_company(
      this.accountingCompanyName,
      null,
      true,
      null,
    );

    console.log("Company Response", addCompanyResponse);
    this.toastr.success("Company created!");
    this.localStorage.set("data", addCompanyResponse.data);
    this.localStorage.set("company", addCompanyResponse.company);
    this.localStorage.set("remember", true);

    this.sharedDataService.subscriptionType = loginResponse.subscription_type;
    this.sharedDataService.data = addCompanyResponse.data;
    this.sharedDataService.company = addCompanyResponse.company;
    this.sharedDataService.user = addUserResponse.user;

    await this.loginService.loginToFirestore(true, true);
  }

  closeDrawer() {
    this.signUpDrawerToggle.emit(false);
    setTimeout(() => {
      this.drawerState = this._isTrial ? "trial" : "initial";
    }, 300);
  }
}
