import { Component, OnInit, TemplateRef, AfterViewInit } from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "underscore";

@Component({
    selector: "newsletter-redirect",
    templateUrl: "./newsletter-redirect.component.html",
    standalone: true,
})
export class NewsletterRedirectComponent implements OnInit, AfterViewInit {
  public data: any = this.localStorage.get("data");

  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    //
  }

  ngAfterViewInit(): void {
    document.location.href = "https://www.getrevue.co/profile/clienthub";
  }

  ngOnInit(): void {}
}
