import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { RegistrationService } from "../registration.service";
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router, ActivatedRoute, Params, RouterLink } from "@angular/router";
import { FormUtil } from "../../utils/formutils";
import { HotToastService } from "@ngneat/hot-toast";
import { NgIf, NgClass } from "@angular/common";

/**
 * Registration main page component.
 */
@Component({
    selector: "app-registration-main",
    templateUrl: "./main.component.html",
    styleUrls: [],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        RouterLink,
    ],
})
export class MainRegistrationComponent implements OnInit {
  public invite: any = null;
  public invite_info: any;
  public emailForm: UntypedFormGroup;
  public formUtil: FormUtil;
  public emailFormSubmitAttempt: boolean;
  loading = true;

  constructor(
    public localStorage: LocalStorageService,
    public registrationService: RegistrationService,
    public formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    private toastr: HotToastService
  ) {}

  ngOnInit(): void {
    this.formUtil = new FormUtil();
    this.emailFormSubmitAttempt = false;
    this.emailForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });

    this.route.params.subscribe((params) => {
      if (params["uuid"] !== undefined) {
        this.registrationService
          .check_invitation(params["uuid"])
          .then((res) => {
            const response = res;

            this.registrationService.email = response.invites.email;
            this.registrationService.invite_info = null;

            if (response.invites != null && response.invites !== undefined) {
              this.invite = true;
              this.invite_info = response.invites;
              this.registrationService.invite_info = this.invite_info;
            } else {
              this.invite = false;
            }

            this.loading = false;
          });
      } else {
        if (this.localStorage.get("registration_email") !== undefined) {
          this.registrationService.email =
            this.localStorage.get("registration_email");
          this.emailForm.patchValue({
            email: this.localStorage.get("registration_email"),
          });
        }

        this.loading = false;
      }
    });
  }

  check_email(): void {
    this.emailFormSubmitAttempt = true;
    if (this.emailForm.valid) {
      this.registrationService
        .check_invitation(this.emailForm.get("email").value)
        .then(
          (res) => {
            const response = res;

            this.registrationService.email = this.emailForm.get("email").value;
            this.registrationService.invite_info = null;
            if (response.invites.length > 0) {
              this.invite = true;
              this.invite_info = response.invites[0];
              this.registrationService.invite_info = this.invite_info;
            } else {
              this.invite = false;
            }
          },
          (err) => {
            this.toastr.error(err.error.message);
          }
        );
    }
  }
}
