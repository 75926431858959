import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { Router, ActivatedRoute, Params, RouterLink } from "@angular/router";
import { HotToastService } from "@ngneat/hot-toast";
import { LoginService } from "./login.service";
import { NgxIntlTelInputModule } from "@justin-s/ngx-intl-tel-input";
import { FormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { NgxTurnstileModule } from "ngx-turnstile";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    FormsModule,
    NgxIntlTelInputModule,
    NgxTurnstileModule,
  ],
})
export class ForgotPasswordComponent implements OnInit {
  public email = "";
  public uuid = "";
  public new_password = "";
  public new_password_confirm = "";

  captchaPassed = false;
  env = environment;

  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    public route: ActivatedRoute,
    public toastr: HotToastService,
    public loginService: LoginService,
    vcr: ViewContainerRef
  ) {}

  ngOnInit(): void {
    if (
      this.localStorage.get("access_token") !== undefined &&
      this.localStorage.get("access_token") != null
    ) {
      if (this.localStorage.get("data")["user_type"] == 1) {
        this.router.navigate(["/practice/workspaces"]);
      } else {
        this.router.navigate(["/client"]);
      }
    }

    this.route.params.subscribe((params) => {
      if (params["uuid"] !== undefined) {
        this.uuid = params["uuid"];
      }
    });
  }

  reset_password(): void {
    this.loginService.forgot_password(this.email).then(
      (res: any) => {
        const response = res;
        this.toastr.success(response.message);
      },
      (err) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  change_password(): void {
    if (this.new_password === this.new_password_confirm) {
      this.loginService.change_password(this.uuid, this.new_password).then(
        (res: any) => {
          const response = res;
          this.toastr.success(response.message);

          setTimeout(() => {
            this.router.navigate(["/login"]);
          }, 2000);
        },
        (err) => {
          this.toastr.error(err.error.message);
        }
      );
    } else {
      this.toastr.error("Passwords must match.");
    }
  }

  captchaResponse(event) {
    this.captchaPassed = true;
  }

  captchaError(event) {
    this.captchaPassed = false;
  }
}
