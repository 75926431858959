<!-- <a class="banner-black-friday" href="https://clienthub.reamaze.com/kb/faqs/client-hub-2021-black-friday-promotion" target="_blank" rel="noreferrer noopener"></a> -->
<header class="top-bar animated fadeInDown" role="banner">
  <div class="container">
    <nav role="navigation">
      <div class="row d-flex align-items-center">
        <div class="col-lg-2">
          <div class="logo-wrapper">
            <a class="logo link link-scroll" href="/#/"
              ><img src="assets/img/logo-default.svg" alt="Client Hub Logo"
            /></a>
            <i
              class="visible-xs visible-sm visible-md feather menu menu-icon"
            ></i>
          </div>
        </div>
        <div class="col-lg-7">
          <ul class="nav-list text-center">
            <li>
              <a class="link" href="/#/product-tour">Product Tour</a>
            </li>

            <!-- <li>
                          <a class="link" href="/#/before-and-after">Before & After</a>
                      </li> -->

            <li>
              <a class="link" href="/#/why-choose-us">Why Choose Us?</a>
            </li>

            <li>
              <a
                class=""
                target="_blank" rel="noreferrer noopener"
                href="https://clienthub.app/blog/tag/case-studies/"
                >Customer Stories
                <i
                  class="feather external-link"
                  style="font-size: 14px; opacity: 0.6"
                ></i
              ></a>
            </li>

            <li>
              <a class="link" href="https://compare.clienthub.app/" target="_blank" rel="noreferrer noopener"
                >Comparisons
                <i
                  class="feather external-link"
                  style="font-size: 14px; opacity: 0.6"
                ></i
              >
                </a
              >
            </li>

            <li *ngIf="this.router.url === '/'">
              <a class="link link-scroll" id="pricing-link" href="#pricing"
                >Pricing</a
              >
            </li>

            <li *ngIf="this.router.url !== '/'">
              <a class="link" href="/#/#pricing">Pricing</a>
            </li>

            <li class="hidden visible-xs visible-sm visible-md">
              <button
                class="btn btn-primary-light sign-in"
                [routerLink]="['/login']"
              >
                Sign In
              </button>
            </li>
            <li class="hidden visible-xs visible-sm visible-md">
              <button
                class="btn btn-primary focus-hero"
                style="float: right"
                (click)="startRegistration()"
              >
                Get a Demo
              </button>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 text-right">
          <button
            class="btn btn-primary-light hidden visible-lg"
            style="float: left"
            [routerLink]="['/login']"
          >
            Sign In
          </button>
          <button
            class="btn btn-primary focus-hero hidden visible-lg"
            style="float: right"
            (click)="startRegistration()"
          >
            Get a Demo
          </button>
        </div>
      </div>
    </nav>
  </div>
</header>
<ng-template #demoScheduleModal class="modal-lg">
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      (click)="modalRef.hide()"
      aria-label="Close"
    >
      <i class="feather x"></i>
    </button>
    <h4 class="modal-title" id="myModalLabel">Schedule meeting</h4>
  </div>
  <div
    class="modal-body"
    style="
      overflow: hidden;
      padding: 0;
      height: 87vh;
      background: url(assets/img/ajax-loader.gif) center center no-repeat;
    "
  >
    <iframe
      style="width: 100%; height: 100%"
      src="https://calendly.com/judiech/discussion?month=2020-01"
      frameborder="0"
    ></iframe>
  </div>
</ng-template>
<app-sign-up-drawer
  [signUpDrawerOpen]="signUpDrawerOpen"
  (signUpDrawerToggle)="signUpDrawerToggle($event)"
></app-sign-up-drawer>
