import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { SignUpDrawerComponent } from "../../sign-up-drawer/sign-up-drawer.component";
import { FooterComponent } from "../../footer/footer.component";
import { NavigationComponent } from "../../navigation/navigation.component";

@Component({
    selector: "app-all-in-one-practice-platform",
    templateUrl: "./all-in-one-practice-platform.component.html",
    styleUrls: ["../../landing-page.component.scss"],
    standalone: true,
    imports: [
        NavigationComponent,
        FooterComponent,
        SignUpDrawerComponent,
    ],
})
export class AllInOnePracticePlatformComponent implements OnInit {
  public email: String = "";
  public data: any = this.localStorage.get("data");
  public signUpDrawerOpen: Boolean = false;

  constructor(
    public localStorage: LocalStorageService,
    public router: Router
  ) {}

  ngOnInit(): void {}

  startRegistration(): void {
    // this.localStorage.set("registration_email", this.email);
    // this.router.navigate(["/registration/join"]);
    this.signUpDrawerToggle(true);
  }

  signUpDrawerToggle(x): void {
    this.signUpDrawerOpen = x;
  }
}
