import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { SignUpDrawerComponent } from "../../sign-up-drawer/sign-up-drawer.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

@Component({
    selector: "app-liscio",
    templateUrl: "./liscio.component.html",
    styleUrls: ["../alternatives.scss"],
    standalone: true,
    imports: [
        BsDropdownModule,
        RouterLink,
        SignUpDrawerComponent,
    ],
})
export class LiscioComponent implements OnInit {
  public modalRef: BsModalRef;
  public data: any = this.localStorage.get("data");
  public email: String = "";
  public signUpDrawerOpen: Boolean = false;

  constructor(
    public modalService: BsModalService,
    public localStorage: LocalStorageService,
    public router: Router
  ) {}

  ngOnInit(): void {}

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

  startRegistration(): void {
    // this.localStorage.set("registration_email", this.email);
    // this.router.navigate(["/registration/join"]);
    this.signUpDrawerToggle(true);
  }

  signUpDrawerToggle(x): void {
    this.signUpDrawerOpen = x;
  }
}
