<app-navigation></app-navigation>
<main id="start" class="fake-body">

  <section id="hero" class="all-in-one-hero">
    <div class="container">
      <h1 class="text-center">All-In-One Practice Platform <small>Yet Adaptable for Your Firm</small></h1>
      <p>As cloud technology has matured over the last few years, your firm has likely purchased several tools to help
        run your firm and workflows productively.</p>
      <p>“Apps are the new silos.” — Hiten Shah, a renowned product leader, said it well.</p>
      <p>Cloud app proliferation has created new problems that look like the old ones. We thought that adopting a “tech
        stack” of cloud technologies would simplify running our accounting or bookkeeping firm. But, it didn’t turn out
        that way.</p>
      <p>We’re out to give you an alternative — a simpler solution that is about more than just saving multiple
        subscription fees.</p>
    </div>
  </section>

  <section id="all-in-one-content">
    <div class="container">
      <div class="row flex-row flex-center">
        <div class="col-sm-6 col-md-6 text-center" style="display: block">
          <img class="img-responsive" style="margin:auto" src="assets/img/landing-page-v4/sad-billy.png"
            alt="Image of a sad accountant while using a lot of apps together">
        </div>
        <div class="col-sm-6 col-md-6 text-center" style="display: block">
          <img class="img-responsive" style="margin:auto" src="assets/img/landing-page-v4/happy-billy.png"
            alt="Image of a happy accountant while using just Client Hub for everyth">
        </div>
      </div>

      <div class="texts text-center">
        <h2>Fewer Tools = Frictionless Workflow</h2>
        <p>Recurring jobs, task checklists, Simple yet powerful work dashboard.</p>
      </div>
      <div class="texts text-center">
        <h2>Fewer Tools = Better Alignment </h2>
        <p>When everyone’s in the same tool, everyone’s looking at the same data. Your team has a more complete understanding of each client — and single, shared understanding of what to do to best serve them. </p>
      </div>
      <div class="texts text-center">
        <h2>Fewer Tools = Less Wasted Cycles</h2>
        <p>While lots of best-of-breed apps will give you more overall features than Client Hub, every added feature creates cognitive complexity across your team. That cognitive friction impacts users in different ways, and it can negatively impact your team’s productivity due to things like more time onboarding and training new staff, spending time constantly evaluating new tools, integrating them and more.</p>
      </div>
      <div class="texts text-center">
        <h2>Yet Adaptable to Your Firm</h2>
        <p>You don’t have to adopt Client Hub and replace all your current systems all at once - here is a framework for different levels of adoption for your firm:</p>
      </div>

      <img class="table-img img-responsive" src="assets/img/landing-page-v4/table-all-in-one.png" alt="A table showing the options to use Client Hub only or along with other apps">
    
      <div class="big-cta green">
        <div class="row">
            <div class="col-sm-3 col-md-3 cta-illustration">
                <img src="assets/img/landing-page-v4/billy-cta-2.png" alt="Image of an accountant">
            </div>
            <div class="col-sm-5 col-md-6">
                <div class="title">Ready to Put All-in-One to Work in Your Practice?</div>
            </div>
            <div class="col-sm-3 col-md-3 text-right">
                <button class="btn btn-success" (click)="startRegistration()">Get a Demo</button>
            </div>
        </div>
      </div>
    </div>
  </section>

</main>
<app-footer></app-footer>
<app-sign-up-drawer [signUpDrawerOpen]="signUpDrawerOpen" (signUpDrawerToggle)="signUpDrawerToggle($event)"></app-sign-up-drawer>