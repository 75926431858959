import {
  Component,
  OnInit,
  TemplateRef,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import * as _ from "underscore";
import { Intercom } from "@supy-io/ngx-intercom";
import Parallax from "parallax-js";
import { HotToastService } from "@ngneat/hot-toast";
import { SignUpDrawerComponent } from "../../../sign-up-drawer/sign-up-drawer.component";
import { FooterComponent } from "../../../footer/footer.component";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { TabsModule } from "ngx-bootstrap/tabs";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { FormsModule } from "@angular/forms";
import { TooltipModule } from "ngx-bootstrap/tooltip";

declare var jQuery: any;

@Component({
    selector: "app-patriot",
    templateUrl: "./patriot.component.html",
    styleUrls: ["../../../landing-page.component.scss"],
    standalone: true,
    imports: [
        TooltipModule,
        FormsModule,
        CarouselModule,
        TabsModule,
        AccordionModule,
        FooterComponent,
        SignUpDrawerComponent,
    ],
})
export class PatriotComponent implements OnInit {
  public modalRef: BsModalRef;
  public email: String = "";
  public password: String = "";
  public fragment: string;
  public data: any = this.localStorage.get("data");
  public playVideo = 0;
  public currentFeature: String = "conversations";
  public signUpDrawerOpen: Boolean = false;

  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    public route: ActivatedRoute,
    public modalService: BsModalService,
    public cdRef: ChangeDetectorRef,
    public intercom: Intercom,
    private toastr: HotToastService
  ) {
    //
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.intercom.boot({
      app_id: "wqv1cgow",
    });
  }

  ngOnInit(): void {
    var scene = document.getElementById("parallax-scene");
    var parallaxInstance = new Parallax(scene);

    //   document.getElementsByClassName('grv-widget-tag')[2]['style'].display = 'none';
    const node = document.querySelector("body");
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.previousSibling !== undefined &&
          mutation.previousSibling !== null
        ) {
          if (
            mutation.previousSibling["className"] !== undefined &&
            mutation.previousSibling["className"] !== null
          ) {
            if (
              mutation.previousSibling["className"].indexOf(
                "grv-widget-tag"
              ) !== -1
            ) {
              if (this.data.user_type !== 1) {
                let element = jQuery(
                  document.getElementsByClassName("grv-widget-tag")[2]
                );
                if (!_.isEmpty(element)) {
                  jQuery(
                    document.getElementsByClassName("grv-widget-tag")[2]
                  )[0]["style"].visibility = "hidden";
                  observer.disconnect();
                }
              }
            }
          }
        }
      });
    });

    observer.observe(node, {
      attributes: true,
      childList: true,
      characterData: true,
    });

    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });

    // jQuery.getScript("https://widget.prefinery.com/widget/v2/7u96gn7g.js");
  }

  public customPricing(): void {
    this.intercom.showNewMessage("Hi, I want a price quote.");
  }

  public copyCode(): void {
    const el = document.createElement("textarea");
    el.value = "PATRIOT";
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.toastr.success("Code copied to clipboard!");
  }

  public customDemo(): void {
    this.intercom.showNewMessage("Hi, I want to schedule a 1/1 demo.");
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

  startRegistration(): void {
    // this.localStorage.set("registration_email", this.email);
    // this.router.navigate(["/registration/join"]);
    this.signUpDrawerToggle(true);
  }

  signUpDrawerToggle(x): void {
    this.signUpDrawerOpen = x;
  }
}
