<app-navigation></app-navigation>
<main id="start" class="fake-body">

  <section id="hero" class="sophisticated-yet-simple-hero">
    <div class="container">
      <h1 class="text-center">Sophisticated Yet Simple <small>Client Hub’s Product Philosophy</small></h1>
      <p>“Simplicity is the ultimate sophistication” — DaVinci*</p>
      <p>There are no shortage of apps that help accountants in running their practice.<br>  
        There are very broad solutions targeted at many types of businesses (Asana, ClickUp), as well as solutions built and marketed for accountants (Senta, Karbon, JetPack Workflow).  And there are deep solutions for specific parts of the workflow (Practice Ignition, ReceiptBank, ShareFile etc.)</p>
      <p>Our customers say that among all these apps (which basically all check the boxes for feature coverage), they chose Client Hub because our “ease of use” or “design” or “modern experience” stands out.</p>
      <p>To help explain this Client Hub secret sauce in words, we can talk about how everything we build in our product experience is founded on these 3 principles:</p>
    </div>
  </section>

  <section id="sophisticated-yet-simple-cards">
    <div class="container">

      <div class="cards">
        <div class="row d-flex align-items-center">
          <div class="col-md-4">
            <div class="card">
              <img src="assets/img/landing-page-v4/instantly-obvious.png" alt="A person delighted with the ease to use of Client Hub">
              <h3>1. “Instantly Obvious”</h3>
              <p>Our workspace concept is so simple that accountants and clients use our platform without any learning curve.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <img src="assets/img/landing-page-v4/modern-metaphors.png" alt="A image of two chat bubbles">
              <h3>2. Modern Metaphors</h3>
              <p>Each and every feature is lightweight and modern, e.g. communication similar to text messaging.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <img src="assets/img/landing-page-v4/client-centric.png" alt="A image of a client list">
              <h3>3. Client-Centric</h3>
              <p>Everything is organized by client, fitting the natural mental model of accounting professionals.</p>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 150px;">
        <p>These 3 principles result in our overall product being “Sophisticated Yet Simple”</p>
        <p>* By the way, DaVinci <a href="https://checkyourfact.com/2019/07/19/fact-check-leonardo-da-vinci-simplicity-ultimate-sophistication/" target="_blank" rel="noreferrer noopener">probably never said this</a>, but it was a <a href="https://www.computerhistory.org/collections/catalog/102637933" target="_blank" rel="noreferrer noopener">tagline for the Apple II computer</a>. Either way, we believe this insight is awesome and worthy of adopting as our product philosophy!</p>
      </div>

      <div class="big-cta blue">
        <div class="row">
            <div class="col-sm-3 col-md-3 cta-illustration">
                <img src="assets/img/landing-page-v4/billy-cta-2.png" alt="Image of an accountant">
            </div>
            <div class="col-sm-5 col-md-6">
                <div class="title">Ready to Put All-in-One to Work in Your Practice?</div>
            </div>
            <div class="col-sm-3 col-md-3 text-right">
                <button class="btn btn-primary" (click)="startRegistration()">Get a Demo</button>
            </div>
        </div>
      </div>
    </div>
  </section>

</main>
<app-footer></app-footer>
<app-sign-up-drawer [signUpDrawerOpen]="signUpDrawerOpen" (signUpDrawerToggle)="signUpDrawerToggle($event)"></app-sign-up-drawer>