import { Component, OnInit, TemplateRef, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SignUpDrawerComponent } from "../../sign-up-drawer/sign-up-drawer.component";
import { FooterComponent } from "../../footer/footer.component";
import { NgClass, NgIf } from "@angular/common";
import { NavigationComponent } from "../../navigation/navigation.component";

declare var jQuery: any;

@Component({
    selector: "app-customer-council",
    templateUrl: "./customer-council.component.html",
    styleUrls: ["../../landing-page.component.scss"],
    standalone: true,
    imports: [
        NavigationComponent,
        NgClass,
        NgIf,
        FooterComponent,
        SignUpDrawerComponent,
    ],
})
export class CustomerCouncilComponent implements OnInit {
  public modalRef: BsModalRef;
  public email: String = "";
  public data: any = this.localStorage.get("data");
  public signUpDrawerOpen: Boolean = false;

  public drawerOpen = false;
  public member = 0;

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeDrawer();
  }

  constructor(
    public localStorage: LocalStorageService,
    public router: Router,
    public modalService: BsModalService
  ) {}

  ngOnInit(): void {}

  seeMember(id): void {
    this.drawerOpen = true;
    this.member = id;
  }

  closeDrawer(): void {
    this.drawerOpen = false;
  }

  startRegistration(): void {
    // this.localStorage.set("registration_email", this.email);
    // this.router.navigate(["/registration/join"]);
    this.signUpDrawerToggle(true);
  }

  signUpDrawerToggle(x): void {
    this.signUpDrawerOpen = x;
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }
}
