<div class="fake-body">
  <div class="main">
    <header class="header-bar animated fadeIn delay-1">
      <div class="container">
        <div class="practice-logo text-center">
          <a routerLink="/"
            ><img
              src="assets/img/logo-default.svg"
              width="200"
              alt="Cloud Client Hub"
          /></a>
        </div>
      </div>
    </header>
    @if (captchaPassed == false) {
    <ngx-turnstile
      style="display: flex; justify-content: center; margin-top: 30px"
      [siteKey]="env.captchaSiteKey"
      (resolved)="captchaResponse($event)"
      (errored)="captchaError($event)"
      theme="auto"
      [tabIndex]="0"
    ></ngx-turnstile>
    } @if (captchaPassed == true) {
    <div class="container text-center widgets animated fadeInUp delay-3">
      <div class="panel panel-default panel-login text-left">
        <div class="panel-heading">Password Reset</div>
        <div class="panel-body" *ngIf="uuid == ''">
          <p>
            To reset your password, enter the email address you use to sign in
            to Client Hub.
          </p>
          <div class="form-group">
            <label for="inputEmail01">Email address</label>
            <input
              [(ngModel)]="email"
              id="inputEmail01"
              [ngModelOptions]="{ standalone: true }"
              type="email"
              class="form-control"
              placeholder="user@domain.com"
              autofocus
            />
          </div>
          <button
            type="submit"
            name="submit"
            class="btn btn-success btn-block btn-lg"
            (click)="reset_password()"
          >
            Get Reset Link
          </button>
        </div>
        <div class="panel-body" *ngIf="uuid != ''">
          <p>Enter your new password.</p>
          <p>
            It must contain capital and lower-case letters, symbols, numbers and
            at least 8 characters.
          </p>
          <div class="form-group">
            <label for="inputPass01">New password</label>
            <div class="input-password">
              <input
                [(ngModel)]="new_password"
                id="inputPass01"
                [ngModelOptions]="{ standalone: true }"
                type="password"
                class="form-control"
                placeholder="New password"
                autofocus
              />
              <i class="feather eye-off"></i>
            </div>
          </div>

          <div class="form-group">
            <label for="inputPass01">Enter your new password again</label>
            <div class="input-password">
              <input
                [(ngModel)]="new_password_confirm"
                id="inputPass01"
                [ngModelOptions]="{ standalone: true }"
                type="password"
                class="form-control"
                placeholder="Confirm your new password"
                autofocus
              />
              <i class="feather eye-off"></i>
            </div>
          </div>
          <button
            type="submit"
            name="submit"
            class="btn btn-success btn-block btn-lg"
            (click)="change_password()"
          >
            Change Password
          </button>
        </div>
      </div>
    </div>
    }
  </div>
</div>
