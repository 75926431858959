import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { environment } from "../../environments/environment";

@Injectable()
export class RegistrationService {
  public invite_info: any;
  public email: any;

  constructor(
    public http: HttpClient,
    public localStorage: LocalStorageService
  ) {}

  check_invitation(email): Promise<any> {
    const data = {};

    if (email.indexOf("@") !== -1) {
      data["email"] = email;
    } else {
      data["uuid"] = email;
    }

    // headers.set('Authorization', 'JWT ' + this.localStorage.get('access_token'));
    return this.http
      .post(`${environment.apiUrl}/user/invite.check`, data)
      .toPromise();
  }
}
