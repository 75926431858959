<app-navigation></app-navigation>
<main id="start" class="fake-body">
  <section class="about-us">
    <div class="container">
      <div class="section-title text-center">
        <h2>What we are all about</h2>
      </div>

      <div class="row">
        <div class="col-md-4">
          <h4>
            Our Story
          </h4>
        </div>
        <div class="col-md-8">
          <h2>
            We’re a team of accounting professionals and technology-savvy
            innovators
          </h2>
          <p>
            Client Hub is the rare startup that has roots in the profession. As
            a co-founder, I, Judie McCarthy, noticed that while our firm had
            spent a lot of time optimizing internal workflows, things still came
            to a halt when we had questions for clients.
          </p>
          <p>
            We knew that this was a pain point that was shared across firms of
            all sizes -- and it could be addressed by reimagining client
            collaboration through modern platforms and experiences.
          </p>
          <p>
            We teamed up with our technology founding members and brought Client
            Hub to market in early 2018. Today, Client Hub is used by thousands
            of users on a daily basis across our web platform and mobile apps.
          </p>
          <p>
            We have been honored to be recognized by many thought leaders from
            the accounting profession as well as the AICPA as a startup “that
            can transform the profession.”
          </p>
          <p>
            We look forward to having you and your clients join us too.
            <br /><br />

            Cheers!<br />
            Judie
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <h4>
            What value we deliver…
          </h4>
        </div>
        <div class="col-md-8">
          <h2>The Ultimate Frictionless Workflow</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <h4>
            How we do it is special…
          </h4>
        </div>
        <div class="col-md-8">
          <div class="row about-us-items">
            <div class="col-md-4">
              <img src="assets/img/001-collective.svg" alt="Client - Centric" />
              <strong>Client - Centric</strong>
              <p>
                Unlike most accountant tools, we design with client experience
                top of mind.
              </p>
            </div>
            <div class="col-md-4">
              <img src="assets/img/002-chat.svg" alt="Mobile - Ready" />
              <strong>Mobile - Ready</strong>
              <p>
                Your clients are out and about. We let them work with you right
                in their mobile app.
              </p>
            </div>
            <div class="col-md-4">
              <img src="assets/img/003-laugh.svg" alt="Client - Centric" />
              <strong>Simple to Adopt</strong>
              <p>
                No need to spend your time training clients on a complex tool
                that overwhelms.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <h4>
            While giving you peace of mind…
          </h4>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4">
              <p>We treat security as paramount.</p>
            </div>
            <div class="col-md-4">
              <p>We make no compromises on privacy.</p>
            </div>
            <div class="col-md-4">
              <p>We align to standards such as GDPR.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-bottom:50px !important;">
        <div class="col-md-4">
          <h4>
            Don’t Just Take Our Word for It…
          </h4>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-6">
              <strong>AICPA Startup Accelerator 2019</strong>
              <p>
                One of 3 startups chosen by the AICPA and CPA.com for their
                Startup Accelerator program because of our “potential to
                transform the profession”.
              </p>
            </div>
            <div class="col-md-6">
              <strong>“Accountex Next” 2018</strong>
              <p>
                Chosen to exhibit in the “Accountex Next” section featuring
                exciting emerging technologies.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="judie-bubble">
        <div class="row">
          <div class="col-md-3 text-center">
            <div
              class="about-us-card-bg"
              style="background:url(assets/img/landing-page-v2/about-us/judie.jpg)"
            ></div>
            <a
              href="https://www.linkedin.com/in/judiemccarthy/"
              target="_blank" rel="noreferrer noopener"
            >
              <img
                style="margin: 15px auto;"
                src="assets/img/linkedin.svg"
                alt="Linkedin Link"
              />
            </a>
          </div>
          <div class="col-md-8 col-md-offset-1">
            <h2>Judie McCarthy <small>Co-Founder</small></h2>
            <p>
              Judie is an "accounting technology geek". Her practice Think
              Beyond the Desktop serves SMBs throughout the US utilizing a
              remote team and top cloud applications.
            </p>
            <p>
              In addition, she has worked with multiple software companies to
              guide them to launch in the accounting community.
            </p>
            <p>
              Judie is a die-hard New England Patriots fan and avid animal
              lover. She shares her home in Florida with her husband Bob and 7
              adopted fur-babies (2 dogs, 4 cats, and 1 hedgehog).
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <h4>
            Contact Us
          </h4>
        </div>
        <div class="col-md-8">
          <p>
            Simply use the chat icon on the bottom right to connect with someone
            on our team.
          </p>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
